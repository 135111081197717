
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import moment from 'moment';

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {
  get devicesWithIndex() {
    return this.devices.map(
      (items, index) => ({
        일련번호: items,
        no: index + 1
      }))
  }
  
  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  selectedRow: Array<number | null> = []
  devices: Array<string> = []
  requests: Array<object | null> = []
  isLoading: boolean = true

  출고처: Array<Object> = [];
  일련번호: string = '';
  비고: string | null = null;
  사용구분: string = "수리";
  parameters: Object|null = null;
  request

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  async created(): Promise<void> {
    const depRes = await api.출고처.SHOW_ALL()
    const res = await api.수리요청.FIND_AT_TIME(this.$route.query.id)
    this.request = res.message[0]

    this.출고처 = depRes.data.filter((el) => el.분류 == "생산지")
    this.일련번호 = this.request.단말.일련번호
    console.log(this.출고처)
    console.log(this.requests)

    this.isLoading = false
  }

  async registration(target: string) {
    this.isLoading = true
    if (this.parameters != null) {
      let res = await api.수리요청.APPROVAL(this.$route.query.id, this.userInfo.id, '수리출고')
      if (res.code == 201){
        res = await api.departure.REGISTER(
          this.request.단말.제품명,
          [this.request.단말.일련번호],
          this.비고,
          this.사용구분,
          '인터패스',
          this.parameters.이름,
          this.userInfo.id,
          this.parameters.생성일자
        )
        console.log(res)
        if (res.code == 201 || res.code == 202){
          alert(res.message)
          window.close()
        } else {
          alert(res.message)
        }
      } else {
        alert(res.message)
      }
    } else {
      alert("입력되지 않은 값이 있습니다.")
    }
    this.isLoading = false
  }

}
