import axios, { AxiosError } from 'axios';
import qs from 'qs'
const API_URL = process.env.VUE_APP_API;
axios.defaults.withCredentials = true

export default {
  //로그인 동작
  async LOGIN_ACTION(id: string, password: string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/signin",
        data: { id, password },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          loginToken: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        loginToken: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const loginToken = res.data.loginToken === null ? '' : res.data.loginToken;
    const loginInfo = res.data.user === null ? null : res.data.user
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const login = {
      loginToken,
      loginInfo,
      errorMessage,
    };
    return login;
  },
  async PW_CHANGE_ACTION(id: string, 암호: string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/updateUser",
        data: { id, 암호 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          loginToken: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        loginToken: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    return res;
  },
  async USERINFO_CHANGE_ACTION(id: string, 이름: string, 전화번호: string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/updateUser",
        data: { id, 이름, 전화번호 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          loginToken: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        loginToken: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    return res;
  },
  async LOGOUT_ACTION(): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/logout",
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          loginInfo: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        loginInfo: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const loginInfo = res.data.message === undefined ? '' : res.data.message;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const login = {
      loginInfo,
      errorMessage,
    };
    return login;
  },async REGISTER(
    id: string, 
    암호: string, 
    전화번호: string, 
    이름: string, 
    class_id: number,
    운수사: string|null = null
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/signup",
        data: { id, 암호, 전화번호, 이름, class_id, 운수사 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    const departureInfo = {
      code: res.status,
      message: res.data.message,
    };
    return departureInfo;
  },
  async USER_LIST(class_id?: Number, id?:string): Promise<any> {
    let params = {}

    if(class_id==undefined && id==undefined){
      params = {
        mode: 'ALL'
      }
    } else {
      params = {
        mode: 'Query',
        id: {
          class_id: class_id?class_id:undefined,
          id: id?id:undefined
        }
      }
    }
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/user",
        params
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.userInfo === null ? '' : res.data.userInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const userInfo = {
      data,
      errorMessage,
    };
    return userInfo;
  }
}  