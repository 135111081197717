import axios, { AxiosError } from 'axios';
// import qs from 'qs'
const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true
import router from '@/router';

export default {
  async SHOW_ALL(): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/storage",
        params: {
          mode: 'ALL'
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.storageInfo === null ? '' : res.data.storageInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 출고처Info = {
      data,
      errorMessage,
    };
    return 출고처Info;
  },

  async STORAGE_INFO(이름: string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/storage",
        params: {
          mode: 'Query',
          id: {
            이름
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.storageInfo === null ? '' : res.data.storageInfo[0];
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 출고처Info = {
      data,
      errorMessage,
    };
    return 출고처Info;
  },
  
  async REGISTER(
    이름: string, 
    지역: string, 
    주소:string, 
    출고처담당자_id:string|null = null, 
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/storage",
        data: { 이름, 지역, 주소, 분류:'납품사', 출고처담당자_id },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    const 출고처Info = {
      code: res.status,
      message: res.data.message,
    };
    return 출고처Info;
  },
  async UPDATE(
    이름: string, 
    ID: string,
    주소: string,
    지역: string,
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/storage/update",
        data: { 이름, ID, 주소, 지역 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    const 출고처Info = {
      code: res.status,
      message: res.data.message,
    };
    return 출고처Info;
  },
}  