import { RouteConfig } from 'vue-router';

import store from '@/store';
import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';

import Taxi from '@/views/taxi/TaxiList.vue';
import Summary from '@/views/taxi/Summary.vue';
import TaxiIn from '@/views/taxi/popup/TaxiIn.vue';
import TaxiDetail from '@/views/taxi/popup/TaxiDetail.vue';
import User from '@/views/taxi/user/UserList.vue';

import Common from '@/utils/common';

export const taxiRoutes: RouteConfig[] = [
    {
        path: '/taxi',
        name: 'taxi',
        components: {
            header: Header,
            menu: Menus,
            content: Taxi,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/taxiUser',
        name: 'taxiUser',
        components: {
            header: Header,
            menu: Menus,
            content: User,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/taxi/summary',
        name: 'taxiSummary',
        components: {
            header: Header,
            menu: Menus,
            content: Summary,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/taxi/in',
        name: 'taxiIn',
        components: {
            content: TaxiIn,
        },
        beforeEnter: (to, from, next) => {
            document.title = "차량 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/taxi/detail',
        name: 'taxiDetail',
        components: {
            content: TaxiDetail,
        },
        beforeEnter: (to, from, next) => {
            document.title = "차량 상세";
            Common.requireAuth(to, from, next, null)
        },
    }
    // ...ledger,
    // ...doc,
];

