import { render, staticRenderFns } from "./hold.vue?vue&type=template&id=7bef2e4d&scoped=true&"
import script from "./hold.vue?vue&type=script&lang=ts&"
export * from "./hold.vue?vue&type=script&lang=ts&"
import style0 from "./hold.vue?vue&type=style&index=0&id=7bef2e4d&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7bef2e4d",
  null
  
)

export default component.exports