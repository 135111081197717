import axios, { AxiosError } from 'axios';
// import qs from 'qs'
import router from '@/router';

const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true

export default {
  async COUNT(id?): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/device",
        params: {
          mode: 'Count',
          id
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.storageInfo === null ? '' : res.data.storageInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async TOTAL(id?): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/device",
        params: {
          mode: 'Total',
          id,
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.storageInfo === null ? '' : res.data.storageInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async SHOW_ALL(id?, page?): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/device",
        params: {
          mode: 'ALL',
          id,
          page
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.storageInfo === null ? '' : res.data.storageInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async FIND_AT_SERIAL(일련번호:string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/device",
        params: {
          mode: 'ALL',
          id: {
            일련번호
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.storageInfo === null ? '' : res.data.storageInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async REGISTER(
    제품명: string, 
    일련번호: Array<string>, 
    비고:string|null, 
    사용구분:string, 
    출고처_이름:string, 
    입고처_이름:string, 
    출고담당자_id:string
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/device",
        data: { 제품명, 일련번호, 비고, 사용구분, 출고처_이름, 입고처_이름, 출고담당자_id },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    const departureInfo = {
      code: res.status,
      message: res.data.message,
    };
    return departureInfo;
  },
}  