
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import Approval from './approval.vue';
import Hold from './hold.vue';

import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import moment from 'moment';

@Component({
  name: 'RequestDetailVue',
  components: {
    Loading,
    Approval,
    Hold,
  },
})
export default class RequestDetailVue extends Vue {
  isLoading: boolean = true

  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  get 지역() {
    return this.parameters != null ? this.parameters.지역 : ''
  }
  get 주소() {
    return this.parameters != null ? this.parameters.주소 : ''
  }
  get 담당자() {
    return this.parameters != null ? this.parameters.유저.이름 : ''
  }
  get 연락처() {
    return this.parameters != null ? this.parameters.유저.전화번호 : ''
  }
  출고처: Array<Object> = [];
  일련번호: string = '';
  특이사항: string | null = null;
  MID: File | null = null;
  수량: number = 0;
  품목명: string = "KM100";
  parameters: Object|null = null;

  입출고일(생성일자) {
    return moment(생성일자).format('YYYY-MM-DD HH:mm:ss')
  }
  async created(): Promise<void> {
    const res = await api.출고처.SHOW_ALL()
    this.출고처 = res.data.filter((el) => el.분류 == "납품사")

    this.출고처.map(el => {
      if(el.출고처담당자_id == this.userInfo.id) {
        this.parameters = el
      }
    })
    console.log(this.출고처)
    this.isLoading = false

    this.isLoading = false
  }

  async registration(): Promise<void> {
    this.isLoading = true
    console.log(this.MID)

    if (this.수량 <= 0) {
      alert("수량이 잘못되었습니다.")
      this.isLoading = false
      return
    } else if(this.parameters == null){
      alert("요청사가 선택되지 않았습니다.")
      this.isLoading = false
      return  
    }

    if(this.MID == null){
      const result = confirm("MID 신청 양식이 업로드 되지 않았습니다.\n 이대로 등록하시겠습니까?");
      if(result){
        // 계속 진행
      }else{
        return
      }
    }


    const 요청정보 = await api.출고요청.REGISTER(
      this.품목명,
      this.수량,
      this.parameters.이름,
      this.userInfo.id,
      this.특이사항
    )

    if(요청정보.code=201){
      console.log(요청정보)
      if(this.MID != null){
        const UPLOAD = await api.출고요청.UPLOAD(
          요청정보.data.생성일자,
          'MID',
          this.MID,
        )
        alert(UPLOAD.message)
      } else {
        alert(요청정보.message)
      }
    } else {
      alert(요청정보.message)
    }
    window.close()
    // this.request = 요청정보.message[0]
    // this.history = this.request.출고요청기록
    // console.log(this.request)

    this.isLoading = false
  }
  async refresh(): Promise<void> {
    this.isLoading = true

    const 요청정보 = await api.출고요청.FIND_AT_TIME(it.$route.query.id)
    this.request = 요청정보.message[0]
    this.history = this.request.출고요청기록
    console.log(this.request)

    it.isLoading = false
  }

}
