
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';

@Component({
  name: 'CorpInput',
  components: {},
})
export default class CorpInputVue extends Vue {
  get codeSelectList(): any {
    return this.$store.state.common.codeSelectList;
  }
  get tableSelectList(): any {
    return this.$store.state.common.tableSelectList;
  }
  get fieldSearchResult(): any {
    return this.$store.state.common.fieldSearchResult;
  }
  get parameters(): any {
    return this.$store.state.common.ledgerResponse.corpList.parameters;
  }
  get corpList(): any {
    return this.$store.state.common.ledgerResponse.corpList;
  }
  //운수사탭 데이터
  searchTextRegion1Code: any = ''; //지역1 검색하는 값
  searchTextRegion2Code: any = ''; //지역2 검색하는 값
  parent_no: number = 0;

  created(): void {
    this.getCodeSelectList('IFR', 2451, 'ALL'); //인프라
    this.getCodeSelectList('FRG', 2473, 'ALL'); //지역1
    //this.getCodeSelectList('SRG', 2520, 'ALL'); //지역2
    this.getTableSelectList('getCorp', 'no, corp_id', 'NOMAL'); //운수사
  }

  // CustomItemText(item: any) {
  //   return item.corp_name + '(' + item.corp_id + ')';
  // }

  getCodeSelectList(CodeParentName: String, CodeParnetNo: Number, CodeType: String): void {
    this.$store.dispatch(Constant.CODE_SELECT_LIST, { CodeParentName, CodeParnetNo, CodeType });
  }

  getTableSelectList(tableName: String, params: String, codeType: String): void {
    this.$store.dispatch(Constant.TABLE_SELECT_LIST, { tableName, params, codeType });
  }

  async search(): Promise<void> {
    this.corpList.page = 1;
    await this.$store.dispatch(Constant.CORP_LIST, {});
  }

  //초기화
  async inputReset(): Promise<void> {
    this.parameters.infra_code = 0,
    this.parameters.region1_code = { //지역
      no: 0, 
      id: "ALL", 
      name: "전체"
    }
    this.parameters.region2_code = { //지역2
      no: 0, 
      id: "ALL", 
      name: "전체"
    }
    this.parameters.corp_id = "";
    this.parameters.corp_name = "";
    this.parameters.tel = "";
    this.parameters.corp_number = "";
    this.parameters.fax = "";
    this.parameters.register_name = "";
    await this.$store.dispatch(Constant.CORP_LIST, {});
  }

  changeVal(item: any, target: string) {
    if(typeof(item) == 'string'){
      this.parameters[target] = new Object();
      this.parameters[target].no = 0;
      this.parameters[target].id = "ALL";
      this.parameters[target].name = "전체";
    }
  }
  changeTableSelect(item: any) {
    if(typeof(item) == 'object'){
      this.parameters.corp_id = item.corp_id;
    }
  }
  beforeDestroy(){
    this.parameters.infra_code = 0,
    this.parameters.region1_code = { //지역
      no: 0, 
      id: "ALL", 
      name: "전체"
    }
    this.parameters.region2_code = { //지역2
      no: 0, 
      id: "ALL", 
      name: "전체"
    }
    this.parameters.corp_id = "";
    this.parameters.corp_name = "";
    this.parameters.tel = "";
    this.parameters.corp_number = "";
    this.parameters.fax = "";
    this.parameters.register_name = "";
  }
}
