import axios, { AxiosError } from 'axios';
import router from '@/router';

// import qs from 'qs'
const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true

export default {
  request: axios.create({
    baseURL: API_URL + "v1",
    headers: {
        'Content-Type': 'application/json',
    },
  }),
  async SHOW_ALL(): Promise<any> {
    let res
    try {
      res = await this.request.get("request/fix", {
        params: {
          mode: 'ALL'
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async FIND_AT_TIME(생성일자:string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/fix",
        params: {
          mode: 'Query',
          id: {
            생성일자
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async FIND_AT_SERIAL(일련번호:string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/fix",
        params: {
          mode: 'Query',
          id: {
            일련번호
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async GET_INVALID(): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/fix",
        params: {
          mode: 'Query',
          id: {
            상태: ['수리완료']
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async GET_VALID(): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/fix",
        params: {
          mode: 'Query',
          id: {
            상태: ['검수완료', '수리출고']
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async APPROVAL(생성일자: string, 담당자: string, 상태: string, 특이사항?: string, 승인수량?:number, ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/request/fix/approval",
        data: { 생성일자, 담당자, 상태, 승인수량, 특이사항 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)
    return {
      code: res.status,
      message: res.data.message,
    };
  },
  async REGISTER(
    요청사: string, 
    일련번호: string, 
    담당자:string, 
    특이사항:string|null = null, 
    분류:{중분류: string, 소분류: string}[], 
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/request/fix/regist",
        data: { 요청사, 담당자, 일련번호, 특이사항, 분류 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        console.log(err)
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    return {
      code: res.status,
      message: res.data.message,
      data: res.data.data,
    };
  },
}  