
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';

@Component({
  name: 'DeviceResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class DeviceResultVue extends Vue {
  @Prop() headers!: Array<Object>;
  @Prop() 품목명!: string;
  @Prop() date!: Object;
  @Prop() filter!: Object;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0
  isLoading: boolean = false;
  data: any;
  length: number = 0;

  search: String = '';

  @Watch('data')
  updateTable() {
    this.changeIPP(this.IPP)
  }

  @Watch('filter', { immediate: true, deep: true })
  @Watch('date', { immediate: true, deep: true })
  async updateDate() {
    console.log("update", this.filter)
    const departureRes = await api.departure.DETAIL({num: this.page, limit: this.IPP}, this.date, this.filter, this.품목명)
    const length = await api.departure.COUNTDETAIL({생성일자: this.date, ...this.filter}, this.품목명)
    console.log("res:", length)
    let dataArray = departureRes.data
    
    if(dataArray.length == 0){    
      this.isLoading = true
      return
    }
    
    this.data = await dataArray.map((element, index, org) => {
      element.구분 = (element.입고처_이름 == '인터패스'?true:false)
      element.입고처이름 = (element.입고처_이름 == '인터패스'?element.출고처_이름:element.입고처_이름)
      return element
    })
    console.log("data: ", this.data)
    console.log("data = ", this.data)
    this.pageLength = Math.ceil(length.data/this.IPP)
    this.length = length.data
    this.$forceUpdate();
    }
  
  입출고일(date: string) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
  async pageChanged(page: number): Promise<void> {
    this.page = page;
    
    const res = await api.departure.DETAIL({num: this.page, limit: this.IPP}, this.date, this.filter, this.품목명)
    console.log("SHOW_ALL: ", res)
    this.data = res.data
    this.$forceUpdate();
  }
  
  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = Math.ceil(this.length/this.IPP)
    this.page = 1
    this.updateDate()
  }
  
  numbering(number): string {
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  async created(): Promise<void> {
    let res = await api.departure.COUNTDETAIL({생성일자: this.date}, this.품목명)
    this.length = res.data
    
    if(this.length == 0){    
      this.isLoading = true
      return
    }
    res = await api.departure.DETAIL({num: this.page, limit: this.IPP}, this.date, this.filter, this.품목명)
    console.log("SHOW_ALL: ", res)
    this.data = res.data
    console.log('header:', this.headers)
    this.pageLength = Math.ceil(this.length/this.IPP)

    // await this.getDepartureList();
  }

  detail(item: any) {
    console.log(item)
    // this.$store.dispatch(Constant.Departure_DETAIL, { no, mode } );
    window.open("/#/device/detail?id="+item.일련번호, "PopupWin", "width=1000,height=790")

  }
  
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();
    const departureRes = await api.departure.DETAIL({num: 1, limit: this.length}, this.date, this.filter, this.품목명)

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: "입출고 상세 "+moment().format(),
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "입출고",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    const wsData = departureRes.data.map(el => {
      return {
        입출고일: moment(el.생성일자).local().format('YYYY-MM-DD HH:mm:ss'),
        품목명: el.단말.제품명,
        시리얼번호: el.일련번호,
        사용구분: el.사용구분,
        출고처: el.출고처_이름,
        구분: el.입고처_이름=='인터패스'?"입고":"출고",
        입고처: el.입고처_이름,
        담당자: el.유저.이름,
      }
    });
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), '입출고상세'+moment().format()+'.xlsx');
    console.log(res)
  }
  async searchBranch(item: any) {
  }
  async searchRoute(item: any) {
  }
  async searchVehicle(item: any) {
  }
}
