import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VModal from 'vue-js-modal';
import VueCookie from 'vue-cookie';
import VueDaumPostcode from 'vue-daum-postcode'

Vue.use(VueCookie)
Vue.use(VModal, { dynamicDefault: { draggable: true, resizable: true } })
Vue.use(VueDaumPostcode)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
