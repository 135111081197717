import axios, { AxiosError } from 'axios';
const API_URL = process.env.VUE_APP_API;
import router from '@/router';

export default {
    async DASHBOARD(model): Promise<any> {
        let res
        try {
            res = await axios({
                method: 'get',
                url: API_URL + "v1/car",
                params: {
                    model
                }
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                return {
                    data: null,
                    errorMessage: err.response?.data.message.errors
                }
            }
            return {
                data: null,
                errorMessage: "알수 없는 에러"
            }
        }
        console.log(res)
        const data = res.data.message === null ? '' : res.data.message;
        const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
        const 차량Info = {
            data,
            errorMessage,
        };
        return 차량Info;
    },

    async EXCELDATA(): Promise<any> {
        let res
        try {
            res = await axios({
                method: 'get',
                url: API_URL + "v1/car/excel-data",
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                return {
                    data: null,
                    errorMessage: err.response?.data.message.errors
                }
            }
            return {
                data: null,
                errorMessage: "알수 없는 에러"
            }
        }
        console.log(res)
        const data = res.data.message === null ? '' : res.data.message;
        const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
        const 차량Info = {
            data,
            errorMessage,
        };
        return 차량Info;
    },
}  