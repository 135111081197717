import { RouteConfig } from 'vue-router';

import store from '@/store';
import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';

import Device from '@/views/device/DeviceList.vue';
import DeviceOut from '@/views/device/popup/DeviceOut.vue';
import DeviceIn from '@/views/device/popup/DeviceIn.vue';
import DeviceDetail from '@/views/device/popup/DeviceDetail.vue';

import Common from '@/utils/common';

export const deviceRoutes: RouteConfig[] = [
    {
        path: '/device',
        name: 'device',
        components: {
            header: Header,
            menu: Menus,
            content: Device,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/device/out',
        name: 'deviceOut',
        components: {
            content: DeviceOut,
        },
        beforeEnter: (to, from, next) => {
            document.title = "폐기 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/device/in',
        name: 'deviceIn',
        components: {
            content: DeviceIn,
        },
        beforeEnter: (to, from, next) => {
            document.title = "생산 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/device/detail',
        name: 'deviceDetail',
        components: {
            content: DeviceDetail,
        },
        beforeEnter: (to, from, next) => {
            document.title = "단말 상세";
            Common.requireAuth(to, from, next, null)
        },
    }
    // ...ledger,
    // ...doc,
];

