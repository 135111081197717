import { RouteConfig } from 'vue-router';

import store from '@/store';
import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';

import DepartureAll from '@/views/departureAll/index.vue';
import Departure from '@/views/departure/index.vue';
import DepartureOut from '@/views/departure/popup/DepartureOut.vue';
import DepartureIn from '@/views/departure/popup/DepartureIn.vue';
import DepartureDetail from '@/views/departure/popup/DepartureDetail.vue';

import Common from '@/utils/common';

export const departureRoutes: RouteConfig[] = [
    {
        path: '/departure',
        name: 'departure',
        components: {
            header: Header,
            menu: Menus,
            content: Departure,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },{
        path: '/departureAll',
        name: 'departureAll',
        components: {
            header: Header,
            menu: Menus,
            content: DepartureAll,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/departure/out',
        name: 'departureOut',
        components: {
            content: DepartureOut,
        },
        beforeEnter: (to, from, next) => {
            document.title = "출고 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/departure/in',
        name: 'departureIn',
        components: {
            content: DepartureIn,
        },
        beforeEnter: (to, from, next) => {
            document.title = "입고 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/departure/detail',
        name: 'departureDetail',
        components: {
            content: DepartureDetail,
        },
        beforeEnter: (to, from, next) => {
            document.title = "입출고 내역 상세";
            Common.requireAuth(to, from, next, null)
        },
    }
    // ...ledger,
    // ...doc,
];

