
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';
import StockInput from './StockInput.vue';
import StockResult from './StockResult.vue';

@Component({
  name: 'StockList',
  components: {
    StockInput,
    StockResult,
  },
})
export default class StockListVue extends Vue {
  // get pageLength(): number {
  //   return (Math.ceil(this.$store.state.common.getUser.totalCount / this.getUser.limit ));
  // }
  isLoading: any = false;

  expanded: any = [];
  searchType: object = [
    { text: "전체", value : "ALL" },
    { text: "ID", value : "id", dataType: "str" },
    { text: "이름", value : "name", dataType: "str" },
    { text: "전화번호", value : "phone", dataType: "str" },
    { text: "차량번호", value : "car_number", dataType: "str" },
  ];
  headers: object = [
    { text: 'No', value: 'no', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '유형', value: 'department_code.name', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '직급', value: 'position_code.name', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '회사/운수사', value: 'corp_no.corp_name', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '부서/영업소', value: 'branch_no.branch_name', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '지부', value: 'area_code.name', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: 'ID', value: 'id', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '이름', value: 'name', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '전화번호', value: 'phone', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '차량번호', value: 'car_number', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '입사일자', value: 'join_date', align: 'start', class: 'font-weight-bold caption header-style' },
    { text: '', value: 'action', align: 'right', sortable: false, width: 100, class: 'font-weight-bold caption header-style' },
  ];

  created(): void {
    //if(this.page == undefined) this.page = 1;
    //this.getUser.page = Number(Common.getParameter('page'));
    // this.$store.dispatch(Constant.USER_LIST, {} );
    this.$store.commit('MENU_ACTIVE', { value: 21 });
    this.$store.commit('SET_TITLE',  { title: '재고현황', icon: 'event_note' });
  }
}
