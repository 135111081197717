
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Modal from './EditMemoModal.vue';

@Component({
  name: 'DepatureDetailVue',
  components: {
    Loading,
    Modal,
  },
})
export default class DepatureOutVue extends Vue {
  get devicesWithIndex() {
    return this.devices.map(
      (items, index) => ({
        일련번호: items,
        no: index + 1
      }))
  }
  get 입출고일() {
    return moment(this.$route.query.id).format('YYYY-MM-DD HH:mm:ss')
  }
  headers: object = [
    { text: 'No', value: 'no', class: "white--text" },
    { text: '시리얼번호', value: '일련번호', class: "white--text", },
  ]
  devices: Array<string | null> = []
  isLoading: boolean = true

  출고처: Array<Object> = [];
  구분: string = "출고";
  일련번호: string = '';
  비고: string | null = null;
  사용구분: string = "";
  품목명: string = "";
  parameters: Object = {
    이름: '',
    지역: '',
    주소: '',
    유저: {
      이름: '',
      전화번호: ''
    }
  };
  search: string = ''


  async created(): Promise<void> {
    let res
    res = await api.departure.FIND_AT_TIME(this.$route.query.id)
    console.log(res)
    this.devices = res.data.map(el => el.일련번호)

    const id = res.data[0].출고담당자_id
    const 출고처이름 = res.data[0].출고처_이름 == "인터패스" ? res.data[0].입고처_이름 : res.data[0].출고처_이름
    
    this.구분 = res.data[0].출고처_이름 == "인터패스" ? "출고" : "입고"
    this.사용구분 = res.data[0].사용구분
    this.품목명 = res.data[0].단말.제품명
    this.비고 = res.data[0].비고

    res = await api.출고처.STORAGE_INFO(출고처이름)

    this.parameters = res.data
    
    console.log(this.parameters)

    
    // console.log(this.출고처)
    this.isLoading = false
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: this.parameters.이름+" "+this.$route.query.id+" 입출고 내역",
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "입출고",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = this.devices.map(el => {
      return {
        입출고일: this.$route.query.id,
        구분: this.구분,
        품목명: this.품목명,
        시리얼번호: el,
        사용구분: this.사용구분,
        업체명: this.parameters.이름,
        지역: this.parameters.지역,
        담당자: this.parameters.유저.이름,
        연락처: this.parameters.유저.전화번호,
        주소: this.parameters.주소,
        비고: this.비고
      }
    });
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), this.parameters.이름+'_'+this.$route.query.id+'_입출고_내역.xlsx');
    console.log(res)
  }

  async update(target: string) {
  }

}
