import actions from './actions';
import mutations from './mutations';
import state from './state';

export default {
  state,
  actions,
  mutations,
  getters: {
    getUser(state) {
      console.log("mutations:getUser");
      const data = sessionStorage.getItem("user")
      if(data != null) {
        return JSON.parse(data)
      }
      return null
    }

  },
};