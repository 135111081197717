
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';
import api from '@/api'

import { CITY } from './interface';

interface Query {
  connection: string,
  car_type: number,
  unit_sn: string | undefined,
  company_name: string | undefined,
  car_regnum: string | undefined,
  car_num: string | undefined,
  mdn: string | undefined,
  driver_id1: string | undefined,
  last_connection_month: string,
  city_id: number,
  status: string | undefined
}

@Component({
  name: 'TaxiInput',
  components: {},
})
export default class TaxiInputVue extends Vue {

  date: Object = {start: Common.todayDate(30), end: Common.todayDate(-1)};
  filter: Object = {};
  today: any = Common.todayDate(-1);

  menu1: boolean = false;
  menu2: boolean = false;


  connection = {
    있음: true,
    없음: false
  }
  last_connection_month = {
    '3개월 이내': 3,
    '6개월 이내': 6
  }
  city: CITY[] = []

  get 차량유형리스트(): any[] {
    const car_type = Common.차량유형리스트()
    let list = [{ car_type_id: 0, car_type_name: 'ALL' }]

    for (const key in car_type) {
      if (Object.prototype.hasOwnProperty.call(car_type, key)) {
        const element = car_type[key];
        list.push({ car_type_id: Number(key), car_type_name: element})
      }
    }

    return list
  }

  public parameters: Query = {
    connection: 'ALL',
    car_type: 0,
    unit_sn: undefined,
    company_name: undefined,
    car_regnum: undefined,
    car_num: undefined,
    mdn: undefined,
    driver_id1: undefined,
    last_connection_month: 'ALL',
    city_id: 0,
    status: undefined
  }

  async created(): Promise<void> {
    let res = await api.차량.CITY()

    this.city = [{ city_id: 0, city_name: 'ALL' }]
    this.city.push(...res.cityExists)
  }

  async search(): Promise<void> {
    const query = {
      ...(this.parameters.connection !== 'ALL' && { connection: this.connection[this.parameters.connection] }),
      ...(this.parameters.connection == '있음' && { period: this.date }),
      ...(this.parameters.car_type !== 0 && { car_type: this.parameters.car_type }),
      ...(this.parameters.unit_sn && { unit_sn: this.parameters.unit_sn }),
      ...(this.parameters.company_name && { company_name: this.parameters.company_name }),
      ...(this.parameters.car_regnum && { car_regnum: this.parameters.car_regnum }),
      ...(this.parameters.car_num && { car_num: this.parameters.car_num }),
      ...(this.parameters.mdn && { mdn: this.parameters.mdn }),
      ...(this.parameters.driver_id1 && { driver_id1: this.parameters.driver_id1 }),
      ...(this.parameters.last_connection_month !== 'ALL' && { last_connection_month: this.last_connection_month[this.parameters.last_connection_month] }),
      ...(this.parameters.city_id !== 0 && { city_id: this.parameters.city_id }),
      status: this.parameters.status
    }
    this.$emit('searchEvent', query);
  }

  //초기화
  async inputReset(): Promise<void> {
    this.parameters = {
      connection: 'ALL',
      car_type: 'ALL',
      unit_sn: undefined,
      company_name: undefined,
      car_regnum: undefined,
      car_num: undefined,
      mdn: undefined,
      driver_id1: undefined,
      last_connection_month: 'ALL',
      city_id: 0,
      status: undefined
    }
    this.search();
  }

}
