import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css'; // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    themes: {
      light: {
        MAIN_COLOR: '#313540',
        MAIN_COLOR2: '#1197e1',
        HEADER_COLOR: '#3c3f4a',
        ACTIVE_COLOR: '#616161',
        SUB_COLOR: '#70ABB1',
        SUB_COLOR2: '#FFFFFF',
        TEXT_COLOR: '#f5f5f5',
        TEXT_COLOR2: '#4F605C',
        TEXT_COLOR3: '#BDBDBD',
        RED: '#fc3d5d',
        BLUE: '#348ed8',
        YELLOW: '#F9A825',
        GREEN: '#00C853',
        LIGHT_GREEN: '#7CFC00',
        BACKGROUND_COLOR: '#ECEFF1',
        POINT_COLOR: '#0F4C81',
        TREAT_COLOR: '#747474',
      },
    },
  },

});
