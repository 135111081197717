
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import api from '@/api';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';

import { CITY } from './interface';
import moment from 'moment';
import common from '@/utils/common';

@Component({
  name: 'TaxiResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class TaxiResultVue extends Vue {
  @Prop() headers: array;
  @Prop() data: array;
  @Prop() total!: number;

  selected: any = [];
  status: string = '';

  @Watch('total')
  updateTotal() {
    this.pageLength = Math.ceil(this.total / this.IPP)
  }
  get 차량유형리스트() {
    return common.차량유형리스트
  }
  loading: boolean = false;
  IPP: number = 15
  page: number = 1
  pageLength: number = 0

  search: String = '';

  city: CITY[] = [];

  async pageChanged(page: number): Promise<void> {
    this.page = page;
    this.$emit('pagination', this.page, this.IPP);
  }

  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = Math.ceil(this.total / this.IPP)
    this.page = 1
    this.$emit('pagination', this.page, this.IPP);
  }

  dateformat(date: string): string {
    return moment(date).subtract(9, 'h').format('YYYY-MM-DD HH:mm:ss');
  }

  numbering(number): string {
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  async created(): Promise<void> {
    let res = await api.차량.CITY()

    this.city = res.cityExists
    this.pageLength = Math.ceil(this.total / this.IPP)

    // await this.getDepartureList();
  }

  detail(item: any) {
    // this.$store.dispatch(Constant.Departure_DETAIL, { no, mode } );
    const TaxiDetailVue = window.open("/#/Taxi/detail?id=" + item.mdn, "PopupWin", "width=850,height=615")
    if (TaxiDetailVue) {
      TaxiDetailVue.focus();
      TaxiDetailVue.onunload = () => {
        console.log("close")
        this.$emit('pagination', this.page, this.IPP);

      }
    } else {
      alert('팝업이 차단되어 있습니다. 팝업을 허용해주세요.');
    }
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    this.isLoading = false
    let data = await api.차량.EXCELDATA()
    console.log(data)
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
      Title: "차량 원장 " + moment().format(),
      Subject: "",
      Author: "InterPass",
      Manager: "김어진",
      Company: "InterPass",
      Category: "ERP Data",
      Keywords: "택시, 원장, 차량",
      Comments: "",
      LastAuthor: "",
      CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = data.data
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), '단말기원장_' + moment().format() + '.xlsx');
    console.log(res)
    this.isLoading = true
  }
  async updateStatus(): Promise<void> {
    this.isLoading = false
    await Promise.all(this.selected.map(async e => {
      let res = await api.차량.UPDATE(
        e.mdn,
        undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined,
        undefined, undefined, undefined, undefined, undefined, undefined, this.status
      )
    }));
    this.isLoading = true
    alert("적용되었습니다.")
    this.$emit('pagination', this.page, this.IPP);
  }
  
}
