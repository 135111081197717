
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import { MDN, CITY, FARE, VAN, DAEMON } from '../interface'

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {
  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  driver: Array<boolean> = [false, false, false]

  item: MDN = {
    mdn: '',
    car_vin: '00000000000000000',
    car_type: 21,
    car_num: '',
    car_regnum: '',
    company_name: '',
    driver_id1: '111111111',
    driver_id2: '222222222',
    driver_id3: '333333333',
    vfare_use: false,
    fare_id: 0,
    city_id: 0,
    firmware_id: 0,
    firmware_update: true,
    daemon_update: true,
    speed_factor: 5120,
    rpm_factor: 2000,
    daemon_id: 1,
    konaiMid: '',
    konaiTid: '',
  }

  rules = {
    required: (value: string) => !!value || '필수 입력 항목입니다.',
    size9: (v: string) => v.length == 9 || '자릿수가 맞지 않습니다.',
    size10: (v: string) => v.length == 10 || '자릿수가 맞지 않습니다.',
    size11: (v: string) => v.length == 11 || '자릿수가 맞지 않습니다.',
    size17: (v: string) => v.length == 17 || '자릿수가 맞지 않습니다.',
    number: (v: string) => !isNaN(Number(v)) || '숫자만 입력해주세요.',
    // format 가가99가9999 (가: 한글, 9: 숫자)
    car_num: (v: string) => /^([가-힣]{2})([0-9]{2})([가-힣]{1})([0-9]{4})$/.test(v) || '형식에 맞게 입력해주세요.',

  }
  city: CITY[] = []
  fare: Array<FARE> = []
  van: Array<VAN> = []
  daemon: Array<DAEMON> = []

  verify: boolean = false
  verifyBNO: boolean = false


  get devicesWithIndex() {
    return this.devices.map(
      (items, index) => ({
        일련번호: items,
        no: index + 1
      }))
  }  headers: object = [
    { text: '사업자명', value: 'company_name', class: "white--text", },
    { text: '사업자번호', value: 'car_regnum', class: "white--text", },
    { text: '운전자격번호', value: 'driver_id1', class: "white--text", },
    { text: '차량번호', value: 'car_num', class: "white--text", },
  ]
  selectedRow: Array<MDN&{verify: boolean}> = []
  devices: Array< MDN&{verify: boolean}> = []
  isLoading: boolean = true

  IPP: number = 15
  page: number = 1
  pageLength: number = 0

  
  itemRowBackground(item) {
     return item.verify ? '' : 'warning'
  }

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  async created(): Promise<void> {
    const CITY = await api.차량.CITY()
    const FARE = await api.차량.FARE()
    const VAN = await api.차량.VAN()
    const DAEMON = await api.차량.DAEMON()
    this.city = CITY.cityExists
    this.fare = FARE.fareExists
    this.van = VAN.vanExists
    this.daemon = DAEMON.daemonExists

    console.log(this.daemon)

    this.isLoading = false
  }
  async verifybusiness() {
    console.log("verifybusiness")
    this.verifyBNO = await api.tims.S_BUSINESS(this.item.car_regnum)
    if (this.verifyBNO != true) {
      alert("사업자 등록번호가 올바르지 않습니다.")
    }
  }

  verifyDriver() {
    console.log("verifyDriver")
    const CAR_REG_NO = this.item.car_num
    Promise.all(this.driver.map(async (enable, index) => {
      if (enable) {
        const res = await api.tims.AUTH(CAR_REG_NO, this.item[`driver_id${index + 1}`])
        console.log(res)
        let code_str = "알 수 없는 에러가 발생했습니다."

        if (res.status == 200) {
          return true
        } else {
          alert(res.response.data.message);
          return false
        }
      }
      return true
    })).then((res) => {
      if (res.every(el => el == true)) {
        this.verify = true
      }
    })
  }

  addDevice(item: MDN) {
    if(item.mdn == '') {
      alert('일련번호를 입력해주세요.')
      return
    }
    if (item.car_num == '') {
      alert('차량번호를 입력해주세요.')
      return
    }
    if (item.car_regnum == '') {
      alert('사업자번호를 입력해주세요.')
      return
    }
    if (item.company_name == '') {
      alert('사업자명을 입력해주세요.')
      return
    }

    if (this.devices.some(el => el.mdn == item.mdn)) {
      alert('이미 등록된 모뎀번호입니다.')
      return
    }
    if (this.devices.some(el => el.car_num == item.car_num)) {
      alert('이미 등록된 차량번호입니다.')
      return
    }
    this.devices.push( {...item, verify: true })
    this.pageLength = Math.ceil(this.devices.length / this.IPP)
  }
  readFile(event) {
    // get File object from input tag
    const file = event.target.files[0];
    // const fileName = file.name;

    // declare FileReader, temp result
    const reader = new FileReader();

    // if you use "this", don't use "function(e) {...}"
    reader.onload = (e) => {
      try {
        if(e.target == null) throw new Error("e.target is null");
        let data = e.target.result
        // data = new Uint8Array(data);
        // get excel file
        let excelFile = XLSX.read(data, { type: 'binary' });

        // get prased object
        excelFile.SheetNames.forEach(sheetName => {
          
          console.log('SheetName: ' + sheetName);
          let rows = XLSX.utils.sheet_to_json(excelFile.Sheets[sheetName]);
          let upload = {
            OK: 0,
            ERROR: 0,
          }
          Promise.all(rows.map(async element => {
            let verify = false;
            let item: MDN = {
              mdn: '',
              car_vin: '00000000000000000',
              car_type: 21,
              car_num: '',
              car_regnum: '',
              company_name: '',
              driver_id1: '111111111',
              driver_id2: '222222222',
              driver_id3: '333333333',
              vfare_use: false,
              fare_id: 0,
              city_id: 0,
              firmware_id: 0,
              firmware_update: true,
              daemon_update: true,
              speed_factor: 5120,
              rpm_factor: 2000,
              daemon_id: 1,
              konaiMid: '',
              konaiTid: '',
            }
            // check input elements with promise all
            let verifyC;
            let verifyF;
            let verifyV;
            let verifyD;
            try {
              const res = await Promise.all([
                // api.tims.S_BUSINESS(element['사업자번호']+''),
                // api.tims.AUTH(element['차량번호'], element['운전자격번호']+''),
                (verifyC = this.city.find(el => el.city_name == element['시경계'])?.city_id ?? null) !== null,
                (verifyF = this.fare.find(el => el.fare_name == element['요금'])?.fare_id ?? null) !== null,
                (verifyV = this.van.find(el => el.firmware_name == element['벤사'])?.firmware_id ?? null) !== null,
                (verifyD = this.daemon.find(el => el.daemon_name == element['데몬'])?.daemon_id ?? null) !== null,
                element['운전자격번호'].length == 9 ? true : false,
                element['모뎀번호'].length == 11 ? true : false,
              ])
              console.log(res)
              console.log(res.every(el => el == true))
              if (res.every(el => el == true)){
                verify = true
                upload.OK++
              } else{
                upload.ERROR++
              }
            } catch (error) {
              console.error("error: "+error)
              upload.ERROR++
            }

            item.car_regnum = String(element['사업자번호'])
            item.company_name = element['사업자명']
            item.car_type = element['차량유형'] == '개인' ? 22 : 21
            item.city_id = verifyC
            item.firmware_id = verifyV
            item.fare_id = verifyF
            item.daemon_id = verifyD
            item.mdn = element['모뎀번호']
            item.car_num = element['차량번호']
            item.driver_id1 = String(element['운전자격번호'])
            this.devices.push({...item, verify})
          }))
          .then(() => {
            alert("업로드 성공" + upload.OK + "건\n\n업로드 실패" + upload.ERROR + "건\n(중복된 데이터, 데이터 검증 실패 등)")
          })
          .catch(console.log);
        });
      } catch (error) {
        alert("에러가 발생했습니다: "+error);
      }
      
      this.pageLength = Math.ceil(this.devices.length / this.IPP)
      this.isLoading = false
    };
    reader.readAsArrayBuffer(file);
  }
  onInsertButtonClick() {
    this.addDevice(this.item)
    
    this.item = {
      mdn: '',
      car_vin: '00000000000000000',
      car_type: 21,
      car_num: '',
      car_regnum: '',
      company_name: '',
      driver_id1: '111111111',
      driver_id2: '222222222',
      driver_id3: '333333333',
      vfare_use: false,
      fare_id: 0,
      city_id: 0,
      firmware_id: 0,
      firmware_update: true,
      daemon_update: true,
      speed_factor: 5120,
      rpm_factor: 2000,
      daemon_id: 1,
      konaiMid: '',
      konaiTid: '',
    }
    this.verify = false
    this.verifyBNO = false
  }
  onDownloadFailRowButtonClick() {
    const data = this.devices.filter(function (item) {
      return item.verify == false
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, '실패 항목.xlsx');
  }
  onDeleteFailRowButtonClick() {
    this.devices = this.devices.filter(function (item) {
      return item.verify == true
    });
  }
  onDeleteButtonClick() {
    this.selectedRow.forEach(el => {
      console.log("row: ", el)
      this.devices = this.devices.filter(function (item) {
        return item.mdn !== el.mdn
      });
    });
    this.selectedRow = []
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  // onDownladFileButtonClick() {
  //   this.isLoading = true
    
  //   this.isLoading = false
  // }
  onReadFileButtonClick() {
    this.isLoading = true
    window.addEventListener('focus', () => { }, { once: true })

    this.$refs.uploader.click()
  }

  async registration(target: string) {
    this.isLoading = true
    if (this.devices.length != 0) {
      Promise.allSettled(this.devices.map(item =>
        api.차량.REGISTER(
          item.mdn,
          item.car_vin,
          item.car_type,
          item.car_num,
          item.car_regnum,
          item.company_name,
          item.driver_id1,
          item.driver_id2,
          item.driver_id3,
          item.vfare_use,
          item.fare_id,
          item.city_id,
          item.daemon_id,
          item.firmware_id,
          item.speed_factor,
          item.rpm_factor,
          item.daemon_update,
          item.firmware_update,
        )
      ))
      .then(async (res) => {
        const {success, fail} = await res.reduce((acc: {success: any[], fail: any[]}, cur) => {
          if(cur.status == "fulfilled") {
            acc.success.push(cur.value)
          } else {
            acc.fail.push(cur.reason)
          }
          return acc
        }, {success: [], fail: []})
        console.log(res)
        alert(`등록결과\n\t성공: ${success.length}\n\t실패: ${fail.length}`)
        // res.find(el => el.status != 200) ? alert(`등록결과\n성공: ${success.length}\n실패: ${fail.length}`) : alert("등록에 성공하였습니다.")
      })
      .catch((err) => {
        console.log(err)
        alert("등록에 실패하였습니다.")
      })
    } else {
      alert("입력되지 않은 값이 있습니다.")
    }
    this.isLoading = false
  }
}
