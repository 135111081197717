// import { mixin } from 'vue/types/umd';
import * as type from './type';

export interface State {
  id: string;
  class_id: number;
  이름: string;
  암호: string;
  errorMessage: string;
}

const state: State = {
  class_id: 0,
  id: '',
  이름: '',
  암호: '',
  errorMessage: '',
};
export default state;