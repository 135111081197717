
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {
  headers: object = [
    { text: 'No', align: 'center', sortable: false, value: 'no', class: "white--text" },
    { text: '분류', align: 'center', sortable: false, value: 'class', class: "white--text", },
    { text: 'ID', align: 'center', sortable: false, value: 'id', class: "white--text", },
    { text: '이름', align: 'center', sortable: false, value: '이름', class: "white--text", }
  ]
  selectedRow: Array<Object | null> = []
  isLoading: boolean = true
  search: string = ''

  get 지역() {
    return this.parameters != null ? this.parameters.지역 : ''
  }
  get 주소() {
    return this.parameters != null ? this.parameters.주소 : ''
  }

  비밀번호: string = '';
  운수사: Array<Object> = [];
  연락처: string = '';
  이름: string = "";
  ID: string = "";
  분류: string = "";
  parameters: Object | null = null;

  userList: any
  showP: boolean = false;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0


  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  async created(): Promise<void> {
    this.$store.commit('MENU_ACTIVE', { value: 11 });
    this.$store.commit('SET_TITLE', { title: '유저 관리', icon: 'manage_accounts' });
    const res = await api.출고처.SHOW_ALL()
    const UserRes = await api.auth.USER_LIST()
    this.userList = UserRes.data.map((el: {
      class_id: number;
      전화번호: string;
      id: string;
      이름: string;
      출고처s: Array<Object>
    }, index: number) => {
      let Class: string

      switch (el.class_id) {
        case 1:
          Class = "운영자"
          break;

        case 2:
          Class = "관리자"
          break;

        case 3:
          Class = "일반"
          break;

        case 4:
          Class = "운수사"
          break;

        default:
          break;
      }
      return {
        전화번호: el.전화번호,
        no: index + 1,
        class: Class,
        id: el.id,
        이름: el.이름,
        출고처: el.출고처s[0]
      }
    })
    this.운수사 = res.data.filter((el: { 분류: string; }) => el.분류 != "생산지")
    console.log("운수사:", this.운수사)
    this.pageLength = Math.ceil(this.userList.length / this.IPP)

    console.log("user list:", this.userList)
    this.isLoading = false
  }

  onClickRow(value: Object) {
    console.log("clicked:", value)
    if (value.class == "운영자")
      return
    if (this.selectedRow[0] == value) {
      this.이름 = '';
      this.연락처 = '';
      this.ID = '';
      this.분류 = '';
      this.비밀번호 = '';
      this.selectedRow = [];
      this.parameters = null;
      return
    }
    this.이름 = value.이름;
    this.연락처 = value.전화번호;
    this.ID = value.id;
    this.분류 = value.class;
    this.비밀번호 = '';
    this.parameters = value.출고처 ? value.출고처 : null
    this.selectedRow = [value];
  }

  async registration(target: string) {
    let popup: any
    const it = this
    switch (target) {
      case '수정':
        let password_rst
        let userinfo_rst
        let storageinfo_rst
        if (this.비밀번호 != '') {
          password_rst = await api.auth.PW_CHANGE_ACTION(this.ID, this.비밀번호)
          console.log(password_rst)
          if (password_rst.status != 200) {
            alert(password_rst.message)
            return
          }
        }
        userinfo_rst = await api.auth.USERINFO_CHANGE_ACTION(this.ID, this.이름, this.연락처)
        // storageinfo_rst = await api.출고처.UPDATE_AT_ID(this.parameters?.이름, this.ID)
        alert(userinfo_rst.data.message)

        break;

      case '등록':
        if ((this.이름 == '' || this.이름 == null) ||
          (this.연락처 == '' || this.연락처 == null) ||
          (this.ID == '' || this.ID == null) ||
          (this.분류 == '' || this.분류 == null) ||
          (this.비밀번호 == '' || this.비밀번호 == null)) {
          alert("입력되지 않은 값이 있습니다.")
          return
        }
        if (this.분류 == '운수사' && this.parameters == null) {
          alert("입력되지 않은 값이 있습니다.")
          return
        }

        let class_id

        switch (this.분류) {
          case "관리자":
            class_id = 2
            break;

          case "일반":
            class_id = 3
            break;

          case "운수사":
            class_id = 4
            break;

          default:
            break;
        }

        const res = await api.auth.REGISTER(
          this.ID,
          this.비밀번호,
          this.연락처,
          this.이름,
          class_id,
        )
        console.log(res)
        alert(res.message)

        storageinfo_rst = await api.출고처.UPDATE_AT_ID(this.parameters?.이름, this.ID)



        break;

      default:
        break;
    }
    this.isLoading = true
    const UserRes = await api.auth.USER_LIST()
    this.userList = UserRes.data.map((el: { class_id: any; 전화번호: any; id: any; 이름: any; }, index: number) => {
      let Class: string

      switch (el.class_id) {
        case 1:
          Class = "운영자"
          break;

        case 2:
          Class = "관리자"
          break;

        case 3:
          Class = "일반"
          break;

        case 4:
          Class = "운수사"
          break;

        default:
          break;
      }
      return {
        전화번호: el.전화번호,
        no: index + 1,
        class: Class,
        id: el.id,
        이름: el.이름
      }
    })
    this.pageLength = this.userList.length / this.IPP
    this.isLoading = false
    this.$forceUpdate();
  }

}
