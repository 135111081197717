
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';
import api from '@/api'

@Component({
  name: 'DeviceResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class DeviceResultVue extends Vue {  
  headers: Array<Object> = [{ 
      text: '품목명', 
      value: '제품명', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '시리얼번호', 
      value: '일련번호', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '상태', 
      value: '상태', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '지역', 
      value: '출고처.지역', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '보유처', 
      value: '출고처.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '',
      value: 'action', 
      align: 'center', 
      sortable: false, 
      width: 80,
      class: 'font-weight-bold caption header-style' 
    },
  ];
  data!: null;
  total!: number;
  isLoading: boolean = true;

  @Watch('total')
  updateTotal() {
    this.pageLength = Math.ceil(this.total/this.IPP)
  }
  IPP: number = 15
  page: number = 1
  pageLength: number = 0
  limit: Number = 15;

  search: String = '';

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }
  
  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = Math.ceil(this.total/this.IPP)
    this.page = 1
  }
  
  numbering(number): string {
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  async created(): Promise<void> {
    this.isLoading = true
    let res = await api.단말.TOTAL({상태: "불량"})
    console.log("COUNT: ", res)

    this.total = res.data
    
    if(this.total == 0){
      this.isLoading = false
      return
    }
    res = await api.단말.SHOW_ALL({상태: "불량"})
    console.log("SHOW_ALL: ", res)
    this.data = res.data

    this.headers[1].filter = (value) => {
      if (!this.search) return true

      return value.includes(this.search)
    }
    
    this.pageLength = Math.ceil(this.total/this.IPP)
    this.isLoading = false
    // await this.getDepartureList();
  }

  async detail(item: any) {
    console.log(item)
    // this.$store.dispatch(Constant.Departure_DETAIL, { no, mode } );
    const RequestPopupWin:Window = await window.open("/#/fix/checklist?id="+item.일련번호, "fix_checklistPopupWin", "width=1000,height=790")

    const it = this
    RequestPopupWin.onunload = async function() {
      it.isLoading = true

      console.log("close")
      let res = await api.단말.TOTAL({상태: "불량"})
      console.log("COUNT: ", res)

      it.total = res.data
      
      if(it.total == 0){
        it.isLoading = false
        return
      }
      res = await api.단말.SHOW_ALL({상태: "불량"})
      console.log("SHOW_ALL: ", res)
      it.data = res.data

      it.headers[1].filter = (value) => {
        if (!it.search) return true

        return value.includes(it.search)
      }
      
      it.pageLength = Math.ceil(it.total/it.IPP)
      it.isLoading = false
    }

  }
  async searchBranch(item: any) {
  }
  async searchRoute(item: any) {
  }
  async searchVehicle(item: any) {
  }
}
