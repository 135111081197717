
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import MyPage from '@/views/common/MyPage.vue';
import PWChangeModal from '@/views/common/PWChangeModal.vue';
import api from '@/api'

@Component({
  name: 'Header',
  components: {
    MyPage,
    PWChangeModal
  },
})
export default class HeaderVue extends Vue {
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  get mini(): any {
    return this.$store.state.menu.mini;
  }
  set mini(value: any) {
    this.$store.state.menu.mini = value;
  }
  get title(): any {
    return this.$store.state.menu.title;
  }
  myPageModal: boolean = false
  show: boolean = true;

  created(): void {
    if(this.$route.query.mode == 'modal') {
      this.show = false;
    }
  }

  async logOut() {
    document.cookie = 'connect.sid=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'
    await this.$store.dispatch("SET_USER");

    return (location.href = '/');
  }
  //아이템 상세(모달)
  myPage(): void {
    this.myPageModal = true
  }
  closeMyPage(): void {
    this.myPageModal = false
  }


}
