
import { Component, Prop, Vue } from 'vue-property-decorator';
import api from '@/api'
import router from '@/router';
import store from '@/store';

@Component({
  name: 'Login',
})
export default class LoginVue extends Vue {
  get auth(): any {
    return this.$store.state.auth;
  }

  id: string = '';
  암호: string = '';
  rememberId: boolean = false;
  
  created() {
    if(localStorage.getItem('userID') != null){
      this.id = String(localStorage.getItem('userID'));
      this.rememberId = true;
    }
  }
  setFocus(): void {
    this.$refs["password"].$refs.input.focus()
  }
  async login(): Promise<void> {
    await store.commit('CHANGE_ISLOADING', {loadingStatus : true});

    const res = await api.auth.LOGIN_ACTION(this.id, this.암호)
    console.log(res)
    if(res.loginToken == null)
      alert(res.errorMessage)
// this.auth.errorMessage = res.errorMessage

    if(this.rememberId) {
      localStorage.setItem('userID', this.id);
    } else {
      localStorage.removeItem('userID');
    }

    if(res.loginToken != null) {
      this.$store.dispatch("SET_USER", res.loginInfo);
      if(res.loginInfo.class_id < 4){
        await router.push({ name: 'departure' });
        window.location.reload()
      }else{ // 운수사 이하
        await router.push({ name: 'stock_request' });
        window.location.reload()
      }
    }
    await store.commit('CHANGE_ISLOADING', {loadingStatus : false});

    // this.$store.dispatch('LOGIN_ACTION', { id: this.id, password: this.password } );
    // if(this.rememberId) {
    //   localStorage.setItem('userID', this.id);
    // } else {
    //   localStorage.removeItem('userID');
    // }
  }
}
