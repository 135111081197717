
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
  import Loading from '@/layout/Loading.vue';
  import api from '@/api'
  import * as XLSX from 'xlsx/xlsx.mjs';

  @Component({
    name: 'HoldVue',
    components: {
      Loading,
    },
  })

  export default class HoldVue extends Vue {
    @Prop() id: string;

    특이사항: string|null = null;
    dialog = false

    get userInfo(): any {
      return this.$store.getters.getUser;
    }

    async created(): Promise<void> {
      // const res = await api.출고처.SHOW_ALL()
      // this.출고처 = res.data.filter((el) => el.분류 == "납품사")
      // console.log(this.출고처)
      this.isLoading = false
    }
    async update(): Promise<void> {
      
      const res = await api.출고요청.APPROVAL(this.id, this.userInfo.id, '취소처리', this.특이사항)
      alert(res.message)
      this.$emit('click', res)
      this.dialog = false
    }
  }

