
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';

@Component({
  name: 'TableRowSelect',
})
export default class TableRowSelectVue extends Vue {
  @Prop() target: any;
  @Prop() name: any;
  item: any = [15, 30, 50, 100, 300];
  model: any = 15;

  get ledgerResponse(): any {
    return this.$store.state.common.ledgerResponse;
  }

  get common(): any {
    return this.$store.state.common;
  }

  created() {
  }

  async changeLimit(items: Number): Promise<void> {
    this.$emit('changeIPP', items)
  }
}
