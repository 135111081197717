
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import Common from '@/utils/common';
import DeviceInput from './DeviceInput.vue';
import DeviceResult from './DeviceResult.vue';
import api from '@/api'

@Component({
  name: 'DeviceList',
  components: {
    DeviceInput,
    DeviceResult,
  },
})
export default class  DeviceListVue extends Vue {
  // get pageLength(): number {
  //   return (Math.ceil(this.$store.state.common.userInfo.totalCount / this.getUser.limit ));
  // }
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;

  expanded: any = [];
  headers: Array<Object> = [{ 
      text: '품목명', 
      value: '제품명', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '시리얼번호', 
      value: '일련번호', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '상태', 
      value: '상태', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '지역', 
      value: '출고처.지역', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '보유처', 
      value: '출고처.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '',
      value: 'action', 
      align: 'center', 
      sortable: false, 
      width: 80,
      class: 'font-weight-bold caption header-style' 
    },
  ];
  row_data: Array<Object> = []
  total: Number = 0;
  page: Number = 1;
  limit: Number = 15;
  query: Object = {};
  data: Array<Object|null> = []
  
  async openPopup(path: string): Promise<void> {
    const DevicePopupWin = await window.open("/#/device/"+path+"?id="+this.userInfo.id, "DevicePopupWin", "width=1205,height=530")
    const it = this
    DevicePopupWin.onunload = async function() {
      let res = await api.단말.TOTAL()
      it.total = res.data
      
      if(it.total == 0){    
        it.isLoading = true
        return
      }
      res = await api.단말.SHOW_ALL(it.query, {num:it.page,limit:it.limit})
      it.data = res.data
    }
    // popup.addEventListener('beforeunload', function() {
    //   console.log("close")
    //   it.created()
    // });

  }

  async changeFilter(filter: any): Promise<void> {
    console.log("change", filter)
    this.query = JSON.parse(JSON.stringify(filter))

    if(this.query.단말.제품명 == "ALL"){
      this.query.단말.제품명 = undefined
    }
    if(this.query.단말.상태 == "ALL"){
      this.query.단말.상태 = undefined
    }
    console.log(this.query)
    
    let res = await api.단말.TOTAL(this.query)
    this.total = res.data
    res = await api.단말.SHOW_ALL(this.query, {num:this.page,limit:this.limit})
    this.data = res.data
  }
  
  async changePage(num:1,limit:15): Promise<void> {
    const res = await api.단말.SHOW_ALL(this.query, {num,limit})

    console.log("SHOW_ALL: ", res)
    this.data = res.data
    this.page = num
    this.limit = limit
  }

  async created(): Promise<void> {
    // console.log(this.userInfo.id)
    this.$store.commit('MENU_ACTIVE', { value: 42 });
    this.$store.commit('SET_TITLE',  { title: '단말기 원장', icon: 'vibration' });

    let res = await api.단말.TOTAL()
    this.total = res.data
    
    if(this.total == 0){    
      this.isLoading = true
      return
    }
    res = await api.단말.SHOW_ALL(this.query, {num:this.page,limit:this.limit})
    console.log("SHOW_ALL: ", res)
    this.data = res.data
    this.isLoading = true
  }
  
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: "단말기 원장 "+moment().format(),
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "입출고",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    const deviceRes = await api.단말.SHOW_ALL(this.query, {num:1,limit:this.total})

    const wsData = deviceRes.data.map(el => {
      return {

        품목명: el.제품명,
        시리얼번호: el.일련번호,
        상태: el.상태,
        지역: el.출고처.지역,
        보유처: el.출고처.이름,
      }
    });
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), '단말기원장_'+moment().format()+'.xlsx');
    console.log(res)
  }
}
