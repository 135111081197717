import { ActionTree } from 'vuex';
import { State } from './state';

const action: ActionTree<State, any> = {
  SET_USER(context, payload) {
    console.log("actions:SET_USER");
    context.commit("SET_USER", payload);
  },
  UNSET_USER(context, payload) {
    console.log("actions:UNSET_USER");
    context.commit("UNSET_USER", payload);
  }
};

export default action;