
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
  import Loading from '@/layout/Loading.vue';
  import api from '@/api'
  import * as XLSX from 'xlsx/xlsx.mjs';

  @Component({
    name: 'ReceiptVue',
    components: {
      Loading,
    },
  })

  export default class ReceiptVue extends Vue {
    @Prop() id!: string;

    특이사항: string|null = null;
    인수증: File | null = null;

    dialog = false
    isLoading
    get userInfo(): any {
      return this.$store.getters.getUser;
    }

    async created(): Promise<void> {
      this.isLoading = false
    }
    async update(): Promise<void> {
      if(this.인수증 != null){
        const UPLOAD = await api.출고요청.UPLOAD(
          this.id,
          '인수증',
          this.인수증,
        )
        
        if(UPLOAD.code==201){
          console.log(UPLOAD)
          const res = await api.출고요청.APPROVAL(this.id, this.userInfo.id, '수령완료', this.특이사항 ?? undefined)
          alert(res.message)
        } else {
          alert(UPLOAD.message)
        }
      }
      this.dialog = false
            
      this.$emit('click', null)
    }
  }

