import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';
import Login from '@/views/common/Login.vue';
import Default from '@/views/Default.vue';
import User from '@/views/user/UserList.vue';
import Sms from '@/views/sms/UserList.vue';
import Stock from '@/views/stock/StockList.vue';

import {departureRoutes} from './departure';
import {storageRoutes} from './storage';
import {documentRoutes} from './document';
import {deviceRoutes} from './device';
import {requestRoutes} from './request';
import {dashboardRoutes} from './dashboard';
import {taxiRoutes} from './taxi';

import Common from '@/utils/common';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Login',
    components: {
      content: Login,
    },
    props: false,
  },
  {
    path: '/default',
    name: 'default',
    components: {
      header: Header,
      menu: Menus,
      content: Default,
    },
    beforeEnter:(to, from, next) => Common.requireAuth(to, from, next, null),
  },
  {
    path: '/stock',
    name: 'stock',
    components: {
      header: Header,
      menu: Menus,
      content: Stock,
    },
    beforeEnter:(to, from, next) => Common.requireAuth(to, from, next, null),
  },
  {
    path: '/user',
    name: 'user',
    components: {
      header: Header,
      menu: Menus,
      content: User,
    },
    beforeEnter:(to, from, next) => Common.requireAuth(to, from, next, null),
  },
  {
    path: '/sms',
    name: 'sms',
    components: {
      header: Header,
      menu: Menus,
      content: Sms,
    },
    beforeEnter:(to, from, next) => Common.requireAuth(to, from, next, null),
  },
];

const router = new VueRouter({
  routes:[
    ...routes,
    ...departureRoutes,
    ...storageRoutes,
    ...documentRoutes,
    ...deviceRoutes,
    ...requestRoutes,
    ...dashboardRoutes,
    ...taxiRoutes,
  ],
});

export default router;