<template>
  <GChart :type="type" :data="data" :options="options" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';

export const chartType = 'LineChart';

export const chartData = [
  ['Year', 'Sales', 'Expenses'],
  ['2004', 1000, 400],
  ['2005', 1170, 460],
  ['2006', 660, 1120],
  ['2007', 1030, 540],
];

export const chartOptions = {
  title: 'Company Performance',
  legend: { position: 'bottom' },
  width: 700,
  height: 500,
};


export default {
  name: 'LineChart',
  components: {
    GChart,
  },
  data() {
    return {
      type: chartType,
      data: chartData,
      options: chartOptions,
    };
  },
};
</script>
