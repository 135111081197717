import axios, { AxiosError } from 'axios';
const API_URL = process.env.VUE_APP_API;

export default {
  async AUTH(CAR_REG_NO: string, QUALF_NO: string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "external/tims",
        params: {
          CAR_REG_NO,
          QUALF_NO,
        },
      })
    }catch(err){
      return err
    }
    return res;
  },
  async V_BUSINESS(b_no: string, start_dt: string, p_nm: string): Promise<any> {
    let res
    try {
      res = await axios({
        withCredentials: false,
        method: 'post',
        url: "https://api.odcloud.kr/api/nts-businessman/v1/validate",
        params: {
          serviceKey: 'LgtarqxfICDsrP24ZJkT0VWyPXrmEEoLPT9E3DzQ3hFrMMPm9MAkQyOTZM5gmVjJSjae5a5pYFfSZ04EWHNW8g=='
        },
        data: {
          businesses: [
            {
                b_no,
                start_dt,
                p_nm
            }
          ]
        }
      })
    }catch(err){
      return err
    }
    return res.data;
  },
  async S_BUSINESS(b_no: string): Promise<any> {
    let res
    try {
      res = await axios({
        withCredentials: false,
        method: 'post',
        url: "https://api.odcloud.kr/api/nts-businessman/v1/status",
        params: {
          serviceKey: 'LgtarqxfICDsrP24ZJkT0VWyPXrmEEoLPT9E3DzQ3hFrMMPm9MAkQyOTZM5gmVjJSjae5a5pYFfSZ04EWHNW8g=='
        },
        data: {
          b_no: [b_no]
        }
      })
    }catch(err){
      return err
    }
    return res.data.match_cnt === res.data.request_cnt;
  }
}  