import { MutationTree } from 'vuex';
import { State } from './state';
//import axios from "axios";

const mutation: MutationTree<State> = {
  //현재 페이지 메뉴 ACTIVE
  MENU_ACTIVE(state: State, payload: any): void {
    console.log(payload.value)
    state.menuActive = payload.value;
  },

  //상단 타이틀 바인딩
  SET_TITLE(state: State, payload: any): void {
    state.title.name = payload.title;
    state.title.icon = payload.icon;
  },

  //권한에 따른 메뉴 노출 설정
  MENU_SETTING(state: State, payload: any): void {
    let results = payload.checkAuthList.results;
    let menus = state.menus;
    state.authList = payload.checkAuthList.results;
    for(let i=0; i<results.length; i++) {
     console.log(menus)
     console.log(results)
      //console.log(results[i].table_name == 'corps' && results[i].read_status == 1 )
      if(results[i].table_name == 'services' && results[i].read_status == 0) {
        menus[5].show = false;
      }
      if(results[i].table_name == 'csr_services' && results[i].read_status == 0) {
        menus[0].show = false;
      }
      if(results[i].table_name == 'stock' && results[i].read_status == 0) {
        menus[3].show = false;
        menus[3].childrens[0].show = false;
        menus[3].childrens[1].show = false;
      }
      if(
        (results[i].table_name == 'corps' && results[i].read_status == 1 ) ||
        (results[i].table_name == 'branchs' && results[i].read_status == 1 ) ||
        (results[i].table_name == 'vehicles' && results[i].read_status == 1 ) ||
        (results[i].table_name == 'devices' && results[i].read_status == 1 ) ||
        (results[i].table_name == 'device_models' && results[i].read_status == 1 ) ||
        (results[i].table_name == 'inventories' && results[i].read_status == 1 )
      ) {
        menus[2].show = true;
      }
    }

  }
};

export default mutation;