
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import LineChart from './chart/LineChart.vue';
import ColumnChart from './chart/ColumnChart.vue';


import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import Common from '@/utils/common';
import api from '@/api'

@Component({
  name: 'Dashboard',
  components: {
    LineChart,
    ColumnChart,
  },
})
export default class DashboardVue extends Vue {
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;

  total: number = 100000;

  menu1: boolean = false;
  menu2: boolean = false;

  expanded: any = [];
  headers: Array<Object> = [{
    text: '입출고일',
    value: '생성일자',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '담당자',
    value: '출고담당자_id',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '구분',
    value: '구분',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '품목명',
    value: '단말.제품명',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '사용구분',
    value: '사용구분',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '입/출고 수량',
    value: '수량',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '현재고',
    value: '현재고',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '지역',
    value: '출고처.지역',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '입출고처',
    value: '입고처이름',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '비고',
    value: '비고',
    align: 'center',
    sortable: false,
    class: 'font-weight-bold caption header-style'
  }, {
    text: '',
    value: 'action',
    align: 'center',
    sortable: false,
    width: 80,
    class: 'font-weight-bold caption header-style'
  },
  ];
  row_data: Array<Object> = []
  data: Array<Object | null> = []
  차량: Array<Object | null> = []

  @Watch('end_date')
  @Watch('start_date')
  async changeDate(): Promise<void> {
    console.log(this.end_date, this.start_date)
    this.data = []
    this.row_data.forEach(el => {
      moment(el.생성일자, "YYYY-MM-DD") <= moment(this.end_date, "YYYY-MM-DD") &&
        moment(el.생성일자, "YYYY-MM-DD") >= moment(this.start_date, "YYYY-MM-DD") ? this.data.push(el) : null
    })
    console.log(this.data)
  }
  async openPopup(path: string): Promise<void> {
    const DeparturePopupWin = await window.open("/#/departure/" + path, "DeparturePopupWin", "width=1205,height=530")
    const it = this
    DeparturePopupWin.onunload = async function () {
      it.row_data = []
      it.data = []
      console.log("close")
      const departureRes = await api.departure.SHOW_ALL()
      const storageRes = await api.출고처.STORAGE_INFO("인터패스")
      console.log(storageRes)
      let dataArray = departureRes.data

      if (dataArray.length == 0) {
        it.isLoading = true
        return
      }

      dataArray = await dataArray.map((element, index, org) => {
        element.구분 = (element.입고처_이름 == '인터패스' ? true : false)
        element.입고처이름 = (element.입고처_이름 == '인터패스' ? element.출고처_이름 : element.입고처_이름)
        return element
      })
      console.log(dataArray)
      let count = 1
      it.row_data = dataArray.reduce(function (acc, current) {
        count++
        if (acc.findIndex(({ 생성일자 }) => 생성일자 === current.생성일자) === -1) {
          acc.push(current);
          count = 1;
        } else {
          acc[acc.length - 1].수량 = count
        }
        return acc;
      }, []);
      console.log(it.row_data)
      it.row_data[0].현재고 = storageRes.data.현재고s[0].수량 ? storageRes.data.현재고s[0].수량 : 0
      it.data = it.row_data.map((el, index) => {
        index == 0 ?
          null :
          it.row_data[index].현재고 =
          it.row_data[index - 1].구분 ?
            (it.row_data[index - 1].현재고 - it.row_data[index - 1].수량) :
            (it.row_data[index - 1].현재고 + it.row_data[index - 1].수량)


        return moment(el.생성일자, "YYYY-MM-DD") <= moment(it.end_date, "YYYY-MM-DD") &&
          moment(el.생성일자, "YYYY-MM-DD") >= moment(it.start_date, "YYYY-MM-DD") ? el : null
      })
      console.log("data = ", it.data)
    }
    // popup.addEventListener('beforeunload', function() {
    //   console.log("close")
    //   it.created()
    // });

  }

  async changeFilter(filter: any): Promise<void> {
    console.log("change", filter)
    this.data = []
    this.row_data.forEach((el: any) => {

      if (filter.담당자 != null && el.출고담당자_id != filter.담당자) {
        return
      } else if (filter.사용구분 != "ALL" && el.사용구분 != filter.사용구분) {
        return
      } else if (filter.입출고처 != null && el.입고처이름 != filter.입출고처) {
        return
      } else if (filter.지역 != null && el.출고처.지역 != filter.지역) {
        return
      } else if (filter.품목명 != "ALL" && el.단말.제품명 != filter.품목명) {
        return
      } else if (filter.입출고구분 != "ALL") {
        if (filter.입출고구분 == "입고") {
          if (el.구분)
            this.data.push(el)
        } else {
          if (!el.구분)
            this.data.push(el)
        }
      }
    })
  }

  async created(): Promise<void> {
    // console.log(this.userInfo.id)

    this.today = Common.todayDate();

    this.start_date = Common.todayDate(30);
    this.end_date = Common.todayDate();
    this.$store.commit('MENU_ACTIVE', { value: 2 });
    this.$store.commit('SET_TITLE', { title: '대시보드', icon: 'dashboard' });

    const departureRes = await api.departure.SHOW_ALL()
    const storageRes = await api.출고처.STORAGE_INFO("인터패스")
    // const carRes = await api.차량.DASHBOARD()
    console.log(carRes)
    let dataArray = departureRes.data

    if (dataArray.length == 0) {
      this.isLoading = true
      return
    }

    dataArray = await dataArray.map((element, index, org) => {
      element.구분 = (element.입고처_이름 == '인터패스' ? true : false)
      element.입고처이름 = (element.입고처_이름 == '인터패스' ? element.출고처_이름 : element.입고처_이름)
      return element
    })
    console.log(dataArray)
    let count = 1
    this.row_data = dataArray.reduce(function (acc, current) {
      count++
      if (acc.findIndex(({ 생성일자 }) => 생성일자 === current.생성일자) === -1) {
        acc.push(current);
        count = 1;
      } else {
        acc[acc.length - 1].수량 = count
      }
      return acc;
    }, []);
    console.log(this.row_data)
    this.row_data[0].현재고 = storageRes.data.현재고s[0].수량 ? storageRes.data.현재고s[0].수량 : 0
    this.data = this.row_data.map((el, index) => {
      index == 0 ?
        null :
        this.row_data[index].현재고 =
        this.row_data[index - 1].구분 ?
          (this.row_data[index - 1].현재고 - this.row_data[index - 1].수량) :
          (this.row_data[index - 1].현재고 + this.row_data[index - 1].수량)


      return moment(el.생성일자, "YYYY-MM-DD") <= moment(this.end_date, "YYYY-MM-DD") &&
        moment(el.생성일자, "YYYY-MM-DD") >= moment(this.start_date, "YYYY-MM-DD") ? el : null
    })
    console.log("data = ", this.data)
    this.isLoading = true
  }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
      Title: "입출고 내역 " + this.start_date + " ~ " + this.end_date,
      Subject: "",
      Author: "InterPass",
      Manager: "김어진",
      Company: "InterPass",
      Category: "ERP Data",
      Keywords: "입출고",
      Comments: "",
      LastAuthor: "",
      CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = this.data.map(el => {
      return {
        입출고일: el.생성일자,
        담당자: el.출고담당자_id,
        구분: el.구분 ? "입고" : "출고",
        품목명: el.단말.제품명,
        사용구분: el.사용구분,
        "입/출고 수량": el.수량,
        현재고: el.현재고,
        지역: el.출고처.지역,
        입출고처: el.입고처이름,
        비고: el.비고
      }
    });
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), '입출고내역_' + this.start_date + '_' + this.end_date + '.xlsx');
    console.log(res)
  }
}
