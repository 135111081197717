
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import common from '@/utils/common'

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {
  headers: object = [
    { text: 'No', align: 'center', sortable: false, value: 'no', class: "white--text" },
    { text: '지역', align: 'center', sortable: false, value: '지역', class: "white--text", },
    { text: '이름', align: 'center', sortable: false, value: '이름', class: "white--text", }
  ]
  addressModal: boolean = false

  selectedRow: Array<Object | null> = []
  isLoading: boolean = true
  search: string = ''

  get 지역리스트() {
    return common.지역리스트()
  }
  // get 지역() {
  //   return this.parameters != null ? this.parameters.지역 : ''
  // }
  get 연락처() {
    return this.parameters != null ? this.parameters.전화번호 : ''
  }

  
  theme:Object = {
    bgColor: "#ECEFF1", //바탕 배경색
    searchBgColor: "#1197E1", //검색창 배경색
    //contentBgColor: "", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    pageBgColor: "#FFFFFF", //페이지 배경색
    //textColor: "", //기본 글자색
    queryTextColor: "#FFFFFF", //검색창 글자색
    postcodeTextColor: "#FF5252" //우편번호 글자색
    //emphTextColor: "", //강조 글자색
    //outlineColor: "", //테두리
  };

  
  비밀번호: string = '';
  관리자: Array<Object> = [];
  지역: string = '';
  이름: string = "";
  주소: string = "";
  분류: string = "";
  parameters: Object|null = null;

  storageList: Array<any> = []
  showP: boolean = false;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0


  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  onLoad() {
    console.log("load")
    this.주소 = ""
  }
  onComplete(result: VueDaumPostcodeCompleteResult) {
    this.주소 = result.address
    this.addressModal = false
    console.log(result)
  }
  
  async created(): Promise<void> {
    this.$store.commit('MENU_ACTIVE', { value: 12 });
    this.$store.commit('SET_TITLE',  { title: '업체 관리', icon: 'corporate_fare' });
    const res = await api.출고처.SHOW_ALL()
    const UserRes = await api.auth.USER_LIST()
    console.log("storage list:", res)

    this.storageList = res.data.filter((el: { 분류: string; }) => el.분류 == "납품사").map((el, index) => {
      return {
        ...el,
        no: index+1,
      }
    })
    this.관리자 = UserRes.data.filter((el: { class_id: string; }) => el.class_id == 4)
    this.pageLength = Math.ceil(this.storageList.length/this.IPP)

    console.log("storage list:", this.storageList)
    this.isLoading = false
  }

  onClickRow(value: Object) {
    console.log("clicked:", value)
    if(value.class == "운영자")
      return
    if(this.selectedRow[0] == value){
      this.이름 = '';
      this.지역 = '';
      this.주소 = '';
      this.selectedRow=[];
      this.parameters=null;
      return
    }
    this.이름 = value.이름;
    this.지역 = value.지역;
    this.주소 = value.주소;
    this.parameters = value.유저 ? value.유저 : null
    this.selectedRow=[value];
  }
  
  async registration(target: string) {
    let popup: any
    const it = this
    switch (target) {
      case '수정':
        let storage_rst
        storage_rst = await api.출고처.UPDATE(this.이름, this.parameters?.id, this.주소, this.지역)
        if (storage_rst.status != 200){
          alert(storage_rst.message)
          return
        }
        alert(storage_rst.message)

        break;
    
      case '등록':
        console.log("이름: ", this.이름)
        console.log("지역: ", this.지역)
        console.log("주소: ", this.주소)
        if((this.이름 == '' || this.이름 == null) ||
        (this.지역 == '' || this.지역 == null) ||
        (this.주소 == '' || this.주소 == null)){
          alert("입력되지 않은 값이 있습니다.")
          return
        }

        const res = await api.출고처.REGISTER(
          this.이름, 
          this.지역, 
          this.주소, 
          this.parameters?.id
        )
        console.log(res)
        alert(res.message)
        const UserRes = await api.auth.USER_LIST()


        break;
  
      default:
        break;
    }
  }

}
