import { render, staticRenderFns } from "./DepartureIn.vue?vue&type=template&id=71744180&scoped=true&"
import script from "./DepartureIn.vue?vue&type=script&lang=ts&"
export * from "./DepartureIn.vue?vue&type=script&lang=ts&"
import style0 from "./DepartureIn.vue?vue&type=style&index=0&id=71744180&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71744180",
  null
  
)

export default component.exports