
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';
import api from '@/api'

@Component({
  name: 'DepartureInput',
  components: {},
})
export default class DepartureInputVue extends Vue {
public parameters: object = {
  담당자: null,
  사용구분: 'ALL',
  입출고_구분: 'ALL',
  일련번호: undefined,
  입고처_이름: undefined,
  출고처_이름: undefined,
}
private 담당자: Array<string> = []
private 소유지: Array<string> = []

  
  get 모델명리스트() {
    return ['ALL', ...Common.모델명리스트()]
  }
  
  
  get 사용구분리스트() {
    return ['ALL', ...Common.사용구분리스트()]
  }
  

  async created(): Promise<void> {
    const UserRes = await api.auth.USER_LIST()
    console.log(UserRes)
    this.담당자 = UserRes.data.map(el => el.id)

    const OutRes = await api.출고처.SHOW_ALL()
    console.log(OutRes)
    this.소유지 = OutRes.data.map(el => el.이름)
  }


  async search(): Promise<void> {
    
    // this.DepartureList.page = 1;
    // await this.$store.dispatch(Constant.Departure_LIST, {});

    switch (this.parameters.입출고_구분) {
      case '입고':
        this.parameters.입고처_이름 = '인터패스';
        this.parameters.출고처_이름 = undefined;
        break;
      case '출고':
        this.parameters.출고처_이름 = '인터패스';
        this.parameters.입고처_이름 = undefined;
        break;
    
      default:
        this.parameters.입고처_이름 = undefined;
        this.parameters.출고처_이름 = undefined;
        break;
    }
    this.$emit('searchEvent', this.parameters); 
  }

  //초기화
  async inputReset(): Promise<void> {
    this.parameters = {
      제품명: 'ALL',
      사용구분: 'ALL',
      일련번호: undefined,
      입고처_이름: undefined,
      출고처_이름: undefined,
    }
    this.$emit('searchEvent', this.parameters); 
  }
}
