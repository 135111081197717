
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';
import api from '@/api'

@Component({
  name: 'DepartureResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class DepartureResultVue extends Vue {
  headers: Array<Object> = [{ 
      text: '검수일자', 
      value: '생성일자', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '지역', 
      value: '출고처.지역', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '요청사', 
      value: '출고처.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '품목명', 
      value: '단말.제품명', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '시리얼번호', 
      value: '일련번호', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '불량 항목', 
      value: '수리요청체크리스트', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '상태', 
      value: '상태', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '수리담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '특이사항', 
      value: '특이사항', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '', 
      value: 'action', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },
  ];
  data : Array = []
  IPP: number = 15
  page: number = 1
  pageLength: number = 0

  search: String = '';

  @Prop() valid!: boolean;

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }
  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = this.data.length/this.IPP
  }
  numbering(number): string {
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  요청일자(date:string) {
    return date ? moment(date).format('YYYY-MM-DD HH:mm:ss') : ''
  }
  중분류(data:Array<Object>) {
    let message = data.length != 0 ? data[0].중분류 : ''
    message = data.length > 1 ? message + ' 외 ' + (data.length-1) + '개' : message
    return message
  }

  async created(): Promise<void> {
    let res
    if(this.valid)
      res = await api.수리요청.GET_VALID()
    else 
      res = await api.수리요청.GET_INVALID()

    console.log("SHOW_ALL: ", res)
    this.data = res.message

    this.pageLength = this.data.length/this.IPP
  }

  async detail(item: any) {
    console.log(item)
    const RequestPopupWin:Window = await window.open("/#/fix/detail?id="+item.생성일자, "RequestPopupWin", "width=1265,height=810") ?? new Window
    const it = this
    RequestPopupWin.onunload = async function() {
      let res
      if(it.valid)
        res = await api.수리요청.GET_VALID()
      else 
        res = await api.수리요청.GET_INVALID()

      console.log("SHOW_ALL: ", res)
      it.data = res.message

      it.pageLength = it.data.length/it.IPP
    }
  }
  
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: "수리 내역("+(this.valid?'진행중':'완료됨')+') '+moment().format('YYYY-MM-DD HH:mm:ss'),
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "수리",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = this.data.map(el => {
      let 불량항목 = {}
      el.수리요청체크리스트.forEach((el, index) => {
        불량항목['중분류 '+ (index+1)] = el.중분류
        불량항목['소분류 '+ (index+1)] = el.소분류
      })
      return {
        검수일자: el.생성일자,
        지역: el.출고처.지역,
        요청사: el.출고처.이름,
        품목명: el.단말.제품명,
        시리얼번호: el.일련번호,
        ...불량항목,
        상태: el.상태,
        수리담당자: el.유저.이름,
        특이사항: el.특이사항,
      }
    });
    console.log(wsData)
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), '수리_내역_'+(this.valid?'진행중':'완료됨')+'_'+moment().format('YYYY-MM-DD HH:mm:ss')+'.xlsx');
    console.log(res)
  }
}
