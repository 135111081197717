import axios, { AxiosError } from 'axios';
const API_URL = process.env.VUE_APP_API;
import router from '@/router';

export default {
    async COUNT(query?): Promise<any> {
        let res
        try {
            res = await axios({
                method: 'get',
                url: API_URL + "register/connection/count",
                params: {
                    ...query
                }
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push({ name: 'Login' });
                    return
                }
                return {
                    data: null,
                    errorMessage: err.response?.data.message.errors
                }
            }
            return {
                data: null,
                errorMessage: "알수 없는 에러"
            }
        }
        console.log(res)
        return res.data;
    },
    async SHOW_ALL(query?, paging?): Promise<any> {
        let res
        try {
            res = await axios({
                method: 'get',
                url: API_URL + "register/connection",
                params: {
                    ...query,
                    paging
                }
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push({ name: 'Login' });
                    return
                }
                return {
                    data: null,
                    errorMessage: err.response?.data.message.errors
                }
            }
            return {
                data: null,
                errorMessage: "알수 없는 에러"
            }
        }
        console.log(res)
        return res.data;
    },
    async REGISTER(
        mdn: string,
        car_vin: string,
        car_type: number,
        car_num: string,
        car_regnum: string,
        company_name: string,
        driver_id1: string,
        driver_id2: string,
        driver_id3: string,
        vfare_use: boolean,
        fare_id: number,
        city_id: number,
        daemon_id: number,
        firmware_id: number,

        speed_factor: number | undefined,
        rpm_factor: number | undefined,
        daemon_update: boolean | undefined,
        firmware_update: boolean | undefined,

    ): Promise<any> {
        let res
        try {
            res = await axios({
                method: 'post',
                url: API_URL + "register/mdn",
                data: {
                    mdn,
                    car_vin,
                    car_type,
                    car_num,
                    car_regnum,
                    company_name,
                    driver_id1,
                    driver_id2,
                    driver_id3,
                    vfare_use,
                    fare_id,
                    city_id,
                    daemon_id,
                    firmware_id,

                    ...(speed_factor && { speed_factor: speed_factor }),
                    ...(rpm_factor && { rpm_factor: rpm_factor }),
                    ...(daemon_update && { daemon_update: daemon_update }),
                    ...(firmware_update && { firmware_update: firmware_update }),
                },
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push({ name: 'Login' });
                    return
                }
                console.log(err)
                throw new Error(err.response?.data.message);
            }
            return {
                code: 400,
                message: "알수 없는 에러"
            }
        }
        console.log(res)

        const departureInfo = {
            code: res.status,
            message: res.data.message,
        };
        return departureInfo;
    },
    async UPDATE(
        id: string,
        mdn: string | undefined,
        car_vin: string | undefined,
        car_type: number | undefined,
        car_num: string | undefined,
        car_regnum: string | undefined,
        company_name: string | undefined,
        driver_id1: string | undefined,
        driver_id2: string | undefined,
        driver_id3: string | undefined,
        vfare_use: boolean | undefined,
        fare_id: number | undefined,
        city_id: number | undefined,
        daemon_id: number | undefined,
        firmware_id: number | undefined,
        speed_factor: number | undefined,
        rpm_factor: number | undefined,
        daemon_update: boolean | undefined,
        firmware_update: boolean | undefined,
        status: string | undefined,
    ): Promise<any> {
        let res
        try {
            res = await axios({
                method: 'patch',
                url: API_URL + "register/mdn/"+id,
                data: {
                    ...(mdn && { mdn }),
                    ...(car_vin && { car_vin }),
                    ...(car_type && { car_type }),
                    ...(car_num && {car_num}),
                    ...(car_regnum && { car_regnum }),
                    ...(company_name && { company_name }),
                    ...(driver_id1 && { driver_id1 }),
                    ...(driver_id2 && { driver_id2 }),
                    ...(driver_id3 && { driver_id3 }),
                    ...(vfare_use && { vfare_use }),
                    ...(fare_id && { fare_id }),
                    ...(city_id && { city_id }),
                    ...(daemon_id && { daemon_id }),
                    ...(firmware_id && { firmware_id }),
                    ...(speed_factor && { speed_factor }),
                    ...(rpm_factor && { rpm_factor }),
                    ...(daemon_update !== undefined && { daemon_update }),
                    ...(firmware_update !== undefined && { firmware_update }),
                    ...(status && { status }),
                },
            })
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push({ name: 'Login' });
                    return
                }
                console.log(err)
                return {
                    code: err.response?.status,
                    message: err.response?.data.message,
                }
            }
            return {
                code: 400,
                message: "알수 없는 에러"
            }
        }
        
        return res;
    },
}  