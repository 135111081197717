import axios, { AxiosError } from 'axios';
// import qs from 'qs'
const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true

export default {
  async SUMMARY(model='KM100', date?): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/summary",
        params: {
          model,
          date
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.summaryInfo === null ? '' : res.data.summaryInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const summaryInfo = {
      data,
      errorMessage,
    };
    return summaryInfo;
  },
  async TOTAL(model='KM100', date?): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/summary/total",
        params: {
          model,
          date
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.totalInfo === null ? '' : res.data.totalInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const totalInfo = {
      data,
      errorMessage,
    };
    return totalInfo;
  },
  async MONTH(model='KM100', date?): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/summary/month",
        params: {
          model,
          date
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.totalInfo === null ? '' : res.data.totalInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const totalInfo = {
      data,
      errorMessage,
    };
    return totalInfo;
  },
}  