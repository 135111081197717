
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';

@Component({
    name: 'ApprovalVue',
    components: {
        Loading,
    },
})

export default class ApprovalVue extends Vue {
    @Prop() id: string;

    비고: string | null = null;
    dialog = false

    get userInfo(): any {
        return this.$store.getters.getUser;
    }

    async created(): Promise<void> {    
        // const res = await api.출고처.SHOW_ALL()
        // this.출고처 = res.data.filter((el) => el.분류 == "납품사")
        // console.log(this.출고처)
        this.isLoading = false
    }
    async update(): Promise<void> {

        const res = await api.departure.EDIT_MEMO(this.id, this.비고)
        console.log(res)
        alert(res.data)

        this.$emit('update', this.비고)

        this.dialog = false
    }
}

