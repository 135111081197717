
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import api from '@/api'
import common from '@/utils/common';
import TaxiResult from './TaxiResult.vue';
import DepartureTable from './DepartureTable.vue';

@Component({
  name: 'DepartureResult',
  components: {
    TaxiResult,
    DepartureTable,
  },
})

export default class DepartureResultVue extends Vue {
  @Prop() 품목명!: string;

  async created(): Promise<void> {
  }
  
}
