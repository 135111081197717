import axios, { AxiosError } from 'axios';
// import qs from 'qs'
import router from '@/router';
const API_URL = process.env.VUE_APP_API;

// axios.defaults.withCredentials = true

export default {
  async SINGLE(number, message): Promise<any> {
    const form = new FormData();
    form.append('from', '01036563620');
    form.append('to', number);
    form.append('text', message);

    try {
      const res = await axios({
        method: 'post',
        url: "https://apick.app/rest/send_sms",
        headers: {
          "CL_AUTH_KEY": "711095dd6d211e131c0f541a67bb863c"
        },
        data: form
      })

      console.log(res)

      if (res.data.status !== 1) {
        return {
          data: res.data,
          errorMessage: res.data.error,
        }
      }

      return {
        data: res.data,
        errorMessage: null,
      };
    } catch (err) {
      if (err instanceof AxiosError) {
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
  },
  async MULTI(numbers: Array<string>, message): Promise<any> {
    try {
      const res = await axios({
        method: 'post',
        url: API_URL + "v1/sms",
        data: {
          numbers: numbers,
          message: message
        }
      })
      console.log(res)
      return {
        data: res.data,
        errorMessage: null,
      };
    } catch (err) {
      console.error(err)
      if (err instanceof AxiosError) {
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }

  },
}  