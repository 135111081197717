import { RouteConfig } from 'vue-router';

// import store from '@/store';
// import Header from '@/components/common/Header.vue';
// import Menus from '@/components/common/Menus.vue';

import 인수증 from '@/views/document/인수증.vue';

import Common from '@/utils/common';

export const documentRoutes: RouteConfig[] = [
    {
        path: '/document/receipt',
        name: 'receiptDocument',
        components: {
            content: 인수증,
        },
        beforeEnter: (to, from, next) => {
            document.title = "PDF 출력";
            Common.requireAuth(to, from, next, null)
        },
    }
    // ...ledger,
    // ...doc,
];

