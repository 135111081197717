
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';
import api from '@/api'

@Component({
  name: 'DepartureInput',
  components: {},
})
export default class DepartureInputVue extends Vue {
public parameters: object = {
  요청자: null,
  품목명: 'ALL',
  상태: 'ALL',
  지역: null,
  요청사: null,
}
private 담당자: Array<string> = []
private 지역: Array<string> = []
private 요청사: Array<string> = []
  

  get 모델명리스트() {
    return ['ALL', ...Common.모델명리스트()]
  }
  get 상태() {
    return ['ALL', '출고요청', '승인완료', '승인보류', '출고완료', '수령완료',]
  }
  
  get 사용구분리스트() {
    return ['ALL', ...Common.사용구분리스트()]
  }
  

  async created(): Promise<void> {
    const UserRes = await api.auth.USER_LIST()
    console.log(UserRes)
    this.담당자 = UserRes.data.map(el => el.이름)

    const OutRes = await api.출고처.SHOW_ALL()
    console.log(OutRes)
    this.요청사 = OutRes.data.map(el => el.이름)
    this.지역 = OutRes.data.map(el => el.지역)

  }


  async search(): Promise<void> {
    // this.DepartureList.page = 1;
    // await this.$store.dispatch(Constant.Departure_LIST, {});
    this.$emit('searchEvent', this.parameters); 
  }

  //초기화
  async inputReset(): Promise<void> {
    this.parameters = {
      요청자: null,
      품목명: 'ALL',
      상태: 'ALL',
      지역: null,
      요청사: null,
    }
    this.$emit('searchEvent', this.parameters); 
  }

  changeVal(item: any, target: string) {
    if(typeof(item) == 'string'){
      this.parameters[target] = new Object();
      this.parameters[target].no = 0;
      this.parameters[target].id = "ALL";
      this.parameters[target].name = "전체";
    }
  }
}
