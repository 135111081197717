import axios, { AxiosError } from 'axios';
const API_URL = process.env.VUE_APP_API;
import router from '@/router';

export default {
    async VAN(): Promise<any> {
        let res
        try {
            res = await axios(API_URL+'register/class/van'); 
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push('/login')
                }
                return err.response?.data;
            }
            return err
        }
        return res.data;
    },
    async CITY(): Promise<any> {
        let res
        try {
            res = await axios(API_URL+'register/class/city'); 
        } catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push('/login')
                }
                return err.response?.data;
            }
            return err
        }
        return res.data;
    },
    async FARE(): Promise<any> {
        let res
        try {
            res = await axios(API_URL+'register/class/fare'); 
        }
        catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push('/login')
                }
                return err.response?.data;
            }
            return err
        }
        return res.data;
    },
    async DAEMON(): Promise<any> {
        let res
        try {
            res = await axios(API_URL+'register/class/daemon'); 
        }
        catch (err) {
            if (err instanceof AxiosError) {
                if (err.response?.status === 401) {
                    router.push('/login')
                }
                return err.response?.data;
            }
            return err
        }
        return res.data;
    }
}  