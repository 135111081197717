
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'App',
})
export default class AppVue extends Vue {
  get modalLoading(): [object] {
    return this.$store.state.flags.modalLoading;
  }
}
