import axios, { AxiosError } from 'axios';
// import qs from 'qs'
const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true

export default {
  async SHOW_ALL(): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/stock",
        params: {
          mode: 'ALL'
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async FIND_AT_TIME(생성일자:string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/stock",
        params: {
          mode: 'Query',
          id: {
            생성일자
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  
  async GET_VALID(): Promise<any> {
    const 상태 = '승인완료'
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/request/stock",
        params: {
          mode: 'Query',
          id: {
            상태
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return {
      code: res.status,
      message: res.data.requestInfo,
    };
  },
  async APPROVAL(생성일자: string, 담당자: string, 상태: string, 특이사항?: string, 승인수량?:number, ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/request/stock/approval",
        data: { 생성일자, 담당자, 상태, 승인수량, 특이사항 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)
    return {
      code: res.status,
      message: res.data.message,
    };
  },
  async REGISTER(
    제품명: string, 
    수량: number, 
    출고요청사:string, 
    담당자:string, 
    특이사항:string|null = null, 
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/request/stock/regist",
        data: { 제품명, 수량, 출고요청사, 담당자, 특이사항 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    return {
      code: res.status,
      message: res.data.message,
      data: res.data.data,
    };
  },
  async UPLOAD(
    id: string, 
    type: string, 
    file: File,
  ): Promise<any> {
    let res
    try {
      let formData = new FormData();
      formData.append("id", id)
      formData.append("type", type)
      formData.append("file", file)
      res = await axios({
        method: 'post',
        url: API_URL + "v1/request/stock/upload",
        data: formData,
        headers: {'Content-Type': 'multipart/form-data'}
      })
    }catch(err){
      if (err instanceof AxiosError) {
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    return {
      code: res.status,
      message: res.data.message,
      data: res.data.data,
    };
  },

  async DOWNLOAD(
    params: string
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/request/stock/download",
        data: {
          params: params
        },
        responseType: "blob",
      });
    }catch(err){
      if (err instanceof AxiosError) {
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    return res;
  },
}  