
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';

@Component({
  name: 'TableRowSelect',
})
export default class TableFieldSelectVue extends Vue {
  @Prop() name: any;
  get tab(): string {
    return this.$store.state.common.tab;
  }
  get response(): any {
    return this.$store.state.common[this.name];
  }

  tabs = '';
  expand: boolean = false;
  all: boolean = true;
  created(){
    if(this.name === 'serviceResponse') this.tabs = 'serviceList'
    else this.tabs = this.$store.state.common.tab
  }
  mounted() {
    if(localStorage.getItem(this.tabs) != null) {
      let headers = JSON.parse( String(localStorage.getItem(this.tabs)) );
      this.response[this.tabs].headers = headers;
      let serviceHeader = JSON.parse( String(localStorage.getItem('serviceList'))); // 로컬스토리지에서 장비 코드 안나오게 임시 코드 반영
      if(serviceHeader){
        for(let i =0; i <serviceHeader.length; i++){
          if(serviceHeader[i]['text'] == '장비코드' || serviceHeader[i]['text'] == '소분류코드' ||serviceHeader[i]['text'] == '대분류코드'){ 
            localStorage.removeItem('serviceList')
            break
          }
        }

      }
    } 
  }

  ALL(): void {
    if(this.all === true) {
      this.response[this.tab].headers.map( (data: any) => {
        data.align = '';
        data.show = true;
      });
    } else {
      this.response[this.tab].headers.map( (data: any) => {
        data.align = ' d-none';
        data.show = false;
      });
    }
    localStorage.setItem(this.tab, JSON.stringify(this.response[this.tab].headers));
  }

  toggle(item: any): void {
    if(item.show) {
      item.align = '';
    } else {
      item.align = ' d-none';
    }

    localStorage.setItem(this.tab, JSON.stringify(this.response[this.tab].headers));
  }
  
}
