
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import api from '@/api'

@Component({
  name: 'PWChangeModal',
})

export default class PWChangeModalVue extends Vue {
  password: string = '';
  get getUser(): any {
    return this.$store.state.common.getUser;
  }

  private async save(): Promise<void> {
    if(this.password.length === 0){
      alert("패스워드를 입력해주세요.");
    } else {
      const res = await api.auth.PW_CHANGE_ACTION(this.getUser.id, this.password)
      if(res.status == 200){
        alert("변경되었습니다.\n다시 로그인 하십시오")
        this.logOut()
      } else {
        alert("실패하였습니다.")
      }
    }
  }
  private async logOut() {
    await api.auth.LOGOUT_ACTION()
    localStorage.removeItem('accessToken');

    return (location.href = '/');
  }

}
