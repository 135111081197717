export default {
  SELECTED_ITEM_LIST: 'selectedItemList', //목록 아이템 선택
  OPEN_MODAL: 'openModal', //모달 열기
  CLOSE_MODAL: 'closeModal', //모달 닫기
  OPEN_PAGE: 'openPage', //페이지 열기
  OPEN_LEDGER_MODAL: 'openLedgerModal', //원장 모달 열기
  CLOSE_LEDGER_MODAL: 'closeLedgerModal', //원장 모달 닫기
  MY_PAGE: 'myPage', //마이페이지
  SIMPLE_API: 'simpleApi', //api 간단조회

  LIST_RESET: 'listReset', //목록 초기화
  PAGE_CHANGE: 'pageChanged', //페이지 넘버 대입
  CHANGE_LIMIT: 'chagneLimit', //목록 조회 개수 변경
  TAB_CHANGE: 'tabChange', //탭 이동처리

  CODE_SELECT_LIST: 'codeSelectList', //코드 선택 목록
  CODE_SELECT_LIST_ALL: 'codeSelectListAll', //코드 선택 목록 (전체 포함)
  CODE_SELECT_LIST_ETC: 'codeSelectListETC', //코드 선택 목록 (기타 포함)
  TABLE_SELECT_LIST: 'tableSelectList', //테이블 선택 목록
  CODE_SELECTED: 'codeSelected', //코드 선택 동작
  FIELD_SEARCH_SUBMIT_CODE: 'fieldSearchSubmitCode', //텍스트 필드 검색 (text-filed)
  FIELD_SEARCH_SUBMIT_TABLE: 'fieldSearchSubmitTable', //텍스트 필드 검색 (text-filed)
  FIELD_SEARCH_SUBMIT_DATA: 'fieldSearchSubmitTable', //텍스트 필드 검색 (text-filed)
  SAVE: 'save', //저장 공통함수
  UPDATE: 'update', //수정 공통함수
  DELETE: 'delete', //삭제 공통함수
  SELECT_TOTAL: 'selectTotal', //total 조회 공통함수

  USER_LIST: 'userList', //유저 목록 조회
  USER_SAVE: 'userSave', //유저 등록
  USER_DETAIL: 'userDetail', //유저 상세
  USER_UPDATE: 'userUpdate', //유저 수정
  USER_DELETE: 'userDelete', //유저 삭제(사용안함 유저는 물리적으로 삭제할수없음)

  AUTH_LIST : 'authList', //권한 목록 조회
  AUTH_UPDATE : 'authUpdate', //권한 수정
  AUTH_ADD : 'authInsert', //권한 등록
  
  CODE_LIST: 'codeList', //코드 목록 조회
  CODE_GROUP_LIST: 'codeGroupList', //코드 그룹 목록 조회
  CODE_GROUP_PARENT: 'codeGroupParent', //코드 그룹 부모로 형제들 조회
  CODE_SAVE: 'codeSave', //코드 등록
  CODE_DETAIL: 'codeDetail', //코드 상세
  CODE_UPDATE: 'codeUpdate', //코드 수정
  CODE_DELETE: 'codeDelete', //코드 삭제

  REPAIR_CODE_LIST: 'repairCodeList', //수리코드 목록 조회
  REPAIR_CODE_GROUP_LIST: 'repairCodeGroupList', //수리코드 그룹 목록 조회
  REPAIR_CODE_GROUP_PARENT: 'repairCodeGroupParent', //수리코드 그룹 부모로 형제들 조회
  REPAIR_CODE_SAVE: 'repairCodeSave', //수리코드 등록
  REPAIR_CODE_DETAIL: 'repairCodeDetail', //수리코드 상세
  REPAIR_CODE_UPDATE: 'repairCodeUpdate', //수리코드 수정
  REPAIR_CODE_DELETE: 'repairCodeDelete', //수리코드 삭제

  LEDGER_LIST: 'ledgerList', //원장 목록 조회

  CORP_LIST: 'corpList', //운수사 목록 조회
  CORP_SAVE: 'corpSave', //운수사 등록
  CORP_DETAIL: 'corpDetail', //운수사 상세
  CORP_UPDATE: 'corpUpdate', //운수사 수정
  CORP_DELETE: 'corpDelete', //운수사 삭제

  BRANCH_LIST: 'branchList', //영업소 목록 조회
  BRANCH_SAVE: 'branchSave', //영업소 등록
  BRANCH_DETAIL: 'branchDetail', //영업소 상세
  BRANCH_UPDATE: 'branchUpdate', //영업소 수정
  BRANCH_DELETE: 'branchDelete', //영업소 삭제
  BRANCH_DOCUMENT: 'branchDocument', //영업소 문서 조회 (1개)
  BRANCH_CHECKLIST: 'branchCheckList', //영업소 Check List 문서 조회

  VEHICLE_LIST: 'vehicleList', //차량 목록 조회
  VEHICLE_SAVE: 'vehicleSave', //차량 등록
  VEHICLE_DETAIL: 'vehicleDetail', //차량 상세
  VEHICLE_UPDATE: 'vehicleUpdate', //차량 수정
  VEHICLE_DELETE: 'vehicleDelete', //차량 삭제
  VEHICLE_HISTORY_LIST: 'vehicleHistoryList', //차량 히스토리 목록
  VEHICLE_HISTORY_DETAIL: 'vehicleHistoryDetail', //차량 히스토리 상세
  VEHILCE_DOCUMENT: 'vehicleDocument', //차량 문서 조회 (n개)

  ROUTE_LIST: 'routeList', //노선 목록 조회
  ROUTE_DETAIL: 'routeDetail', //노선 상세 조회
  ROUTE_SAVE: 'routeSave', //노선 등록
  ROUTE_UPDATE: 'routeUpdate', //노선 수정
  ROUTE_DELETE: 'routeDelete', //노선 삭제

  DEVICE_LIST: 'deviceList', //디바이스 목록 조회
  DEVICE_SAVE: 'deviceSave', //디바이스 등록
  DEVICE_DETAIL: 'deviceDetail', //디바이스 상세
  DEVICE_UPDATE: 'deviceUpdate', //디바이스 수정
  DEVICE_DELETE: 'deviceDelete', //디바이스 삭제
  DEVICE_HISTORY_LIST: 'deviceHistoryList', //디바이스 히스토리 목록 조회

  DEVICE_MODEL_LIST: 'deviceModelList', //디바이스모델 목록 조회
  DEVICE_MODEL_SAVE: 'deviceModelSave', //디바이스모델 등록
  DEVICE_MODEL_DETAIL: 'deviceModelDetail', //디바이스모델 상세
  DEVICE_MODEL_UPDATE: 'deviceModelUpdate', //디바이스모델 수정
  DEVICE_MODEL_DELETE: 'deviceModelDelete', //디바이스모델 삭제

  INVENTORIE_LIST: 'inventorieList', //부자재 목록 조회
  INVENTORIE_SAVE: 'inventorieSave', //부자재 등록
  INVENTORIE_DETAIL: 'inventorieDetail', //부자재 상세
  INVENTORIE_UPDATE: 'inventorieUpdate', //부자재 수정
  INVENTORIE_DELETE: 'inventorieDelete', //부자재 삭제

  AS_PROJECT_LIST : 'projectList', //프로젝트 목록 조회
  AS_CODE_LIST : 'asCodeList', //프로젝트 목록 조회
  AS_HISTORY_LIST : 'asHistoryList', //과거 접수/처리이력 목록 조회
  AS_REGIST_HISTORY_LIST : 'asRegistHistoryList', //과거 접수이력 목록 조회
  AS_GET_USER_LIST : 'asGetUserList', // a/s 텍스트 필드 검색 (text-filed)

  AS_EDIT : 'editAs', //as 수정
  CLOSE_AS_MODAL : 'closeASModal', //as 모달
  AS_DETAIL: 'ASDetail', //as 상세
  AS_GET_BRANCH : 'getASBranchList', //영업소 목록 조회
  AS_GET_SERVICE_CODE : 'getASServiceCode', //검색조건 공통 조회(타입, 장비코드, 대분류명)
  
  SERVICE_LIST: 'serviceList', //서비스(업무) 목록 조회
  SERVICE_DETAIL: 'serviceDetail', //서비스(업무) 상세 조회
  SERVICE_CODE_LIST: 'serviceCodeList', //서비스 코드 조회
  SERVICE_SAVE_BUS : 'serviceSaveBus', //서비스 접수 등록 (버스)
  SERVICE_SAVE_AGG : 'serviceSaveAgg', //서비스 접수 등록 (집계)
  SERVICE_SAVE_CHARGE : 'serviceSaveCharge', //서비스 접수 등록 (충전기)
  SERVICE_SAVE : 'serviceSave', //서비스 접수 등록 (일반업무/bit)
  SERVICE_UPDATE : 'serviceUpdate', //서비스 접수 수정
  SERVICE_DELETE: 'serviceDelete', //서비스 삭제

  SERVICE_TREAT_LIST: 'serviceTreatList', //서비스 처리 목록
  SERVICE_TREAT_SAVE: 'serviceTreatSave', //서비스 처리 등록
  SERVICE_TREAT_DETAIL: 'serviceTreatDetail', //서비스 처리 상세
  SERVICE_TREAT_UPDATE: 'serviceTreatUpdate', //서비스 처리 수정
  SERVICE_TREAT_CANCEL: 'serviceTreatCancel', //서비스 처리 취소

  STOCK_INSERT_ORDER : 'insertOrder', //출고 등록
  STOCK_INSERT_IN_ORDER : 'updateInOrder', //입고 등록
  STOCK_UPDATE_ORDER : 'updateOutOrder', //요청에 의한 출고 등록
  STOCK_GET_MY_DEVICE : 'getDeviceByHolder', //보유 단말기 조회
  STOCK_GET_MY_INVENTORY : 'getMyInventory', //보유 부자재 조회
  STOCK_GET_MY_INVENTORY_BY_NO : 'getMyInventoryByNo', //보유 부자재 조회(by no)
  STOCK_ADD_MY_DEVICE : 'addMyDevice', //나의 보유 단말기 추가
  STOCK_ADD_MY_INVENRTORY : 'addMyInventory', //나의 보유 부자재 추가
  STOCK_DEL_MY_OUTLIST_DEV : 'deleteMyOutListDevice', //출고대상 선택 삭제(단말기) 
  STOCK_DEL_MY_OUTLIST_INV : 'deleteMyOutListInventory', //출고대상 선택 삭제(부자재)
  STOCK_GET_OUT_ORDER : 'getOutOrderPaging', //출고 목록 조회
  STOCK_GET_IN_ORDER : 'getInOrderPaging', //입고 목록 조회
  STOCK_GET_OUT_ORDER_DETAIL : 'getOutOrderDetail', //출고 상세 조회
  
  U2000_LIST: 'u2000List', //u2000 목록
  U2000_UPLOAD: 'u2000Upload', //u2000 업로드

  STOCK_GET_IN_ORDER_DETAIL : 'getInOrderDetail', //입고 상세 조회
  STOCK_GET_DEVICE_BY_SERIAL : 'getDeviceBySerial', //보유 단말기 시리얼 조회
  STOCK_GET_TREAT_LIST : 'getServiceTreatPaging', //예비품,증차 출고 업무조회
  STOCK_GET_USER_LIST : 'getStockUser', //유저 검색
  STOCK_GET_ORDER : 'getOrderPaging', //입출고 조회
  
  GTV_LIST: 'gtvList', //gtv 목록
  GTV_UPLOAD: 'gtvUpload', //gtv 업로드
  GTV_OPERATE_LIST: 'gtvOperateList', //gtv 운영현황
  GTV_OPERATE_LIST_FIRST: 'gtvOperateListFirst', //gtv 운영현황2
  GTV_OPERATE_LIST_SECOND: 'gtvOperateListSecond', //gtv 운영현황3
  GTV_DAILY_DATA_LIST: 'gtvDailyDataList', //GTV장애 일일건수
  GTV_BS_LIST: 'gtvBsList', //GTV BS 리스트 현황

  STOCK_GET_ORDER_DETAIL : 'getOrderDetail', //입출고 상세조회
  STOCK_GET_EQUIPCODE_LIST : 'getequipmentCoeList', //장비코드 리스트 조회
  STOCK_UPDATE_TREATS : 'updateTreatByNo', //예비품출고 > 상태 변경 
  STOCK_INSERT_TREATS : 'insertTreat', //예비품출고 > 상태 변경 

  STOCK_DASHBOARD: 'stockDashBoard', //재고 대시보드 (공통)
  STOCK_DASHBOARD_CORP: 'stockDashBoardCorp', //재고 운수사 대시보드 조회0
  STOCK_DASHBOARD_INTERPASS: 'stockDashBoardInterPass', //재고 인터패스 대시보드 조회0
  STOCK_DASHBOARD_INTERPASS_MODAL: 'stockDashBoardInterPassModal', //재고 인터패스 대시보드 조회1
  STOCK_DASHBOARD_HOLDER: 'stockDashBoardHolder', //재고 나의 대시보드 조회0
  STOCK_DASHBOARD_MODAL: 'stockDashBoardModal', //재고 대시보드 모달창1
  STOCK_DASHBOARD_DETAIL: 'stockDashBoardDetail', //재고 대시보드 상세2


  EXCEL_DOWN: 'excelDown', //엑셀 다운
  FILE_DELETE: 'fileDelete', //파일 삭제

};