import { RouteConfig } from 'vue-router';

import store from '@/store';
import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';

import StockRequest from '@/views/request/stock/RequestList.vue';
import FixRequest from '@/views/request/fix/RequestList.vue';
import FixCheckList from '@/views/request/fix/popup/CheckList.vue';
import In from '@/views/request/stock/popup/in.vue';
import StockDetail from '@/views/request/stock/popup/detail.vue';
import FixDetail from '@/views/request/fix/popup/detail.vue';
import FixOut from '@/views/request/fix/popup/Out.vue';

import Common from '@/utils/common';

export const requestRoutes: RouteConfig[] = [
    {
        path: '/stock_request',
        name: 'stock_request',
        components: {
            header: Header,
            menu: Menus,
            content: StockRequest,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/stock_request/detail',
        name: 'stockDetail',
        components: {
            content: StockDetail,
        },
        beforeEnter: (to, from, next) => {
            document.title = "출고요청 상세";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/stock_request/in',
        name: 'stockin',
        components: {
            content: In,
        },
        beforeEnter: (to, from, next) => {
            document.title = "출고요청 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/fix_request',
        name: 'fix_request',
        components: {
            header: Header,
            menu: Menus,
            content: FixRequest,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/fix',
        name: 'fix_request',
        components: {
            header: Header,
            menu: Menus,
            content: FixRequest,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/fix/checklist',
        name: 'fix_checklist',
        components: {
            content: FixCheckList,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/fix/detail',
        name: 'fixDetail',
        components: {
            content: FixDetail,
        },
        beforeEnter: (to, from, next) => {
            document.title = "수리요청 상세";
            Common.requireAuth(to, from, next, null)
        },
    },
    {
        path: '/fix/out',
        name: 'fixOut',
        components: {
            content: FixOut,
        },
        beforeEnter: (to, from, next) => {
            document.title = "단말 출고";
            Common.requireAuth(to, from, next, null)
        },
    },
    // ...ledger,
    // ...doc,
];

