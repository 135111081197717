
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import Common from '@/utils/common';
import { watch } from 'vue';
import api from '@/api';

@Component({
  name: 'Calendar',
})
export default class Calendar extends Vue {

  @Prop({ default: 'KM100' }) readonly model!: string;

  @Watch('date')
  updateDate() {
    this.headerSet();
    let 재고 = 0
    let stock = Array(this.day)

    const days = [
      {
        division: '출고 수량'
      },
      {
        division: '입고 수량'
      }
    ]
    
    api.단말.TOTAL({소유지: "인터패스", 제품명: this.model}).then((res: any) => {
      재고 = res.data
      api.요약.TOTAL(this.model, this.date).then((res: any) => {
        console.log(res.data)
        재고 = 재고 - res.data.입고수량 + res.data.출고수량
        this.직전재고 = 재고
      })
    })
    
    api.요약.MONTH(this.model, this.date).then((res: any) => {
        days[0]['total'] = res.data.출고수량
        days[1]['total'] = res.data.입고수량
    })
    api.요약.MONTH(this.model, moment(this.date, 'YYYY-MM').subtract(1, "month").format('YYYY-MM')).then((res: any) => {
      Vue.set(days[0], 'priv', res.data.출고수량)
      Vue.set(days[1], 'priv', res.data.입고수량)
    })
  
    api.요약.SUMMARY(this.model, this.date).then((res: any) => {

      console.log(res.data)
      res.data.sort((a: any, b: any) => {
        return moment(a.date, 'YYYY-MM-DD').date() - moment(b.date, 'YYYY-MM-DD').date()
      })
      res.data.forEach((e: any) => {
        e.출고건수 ? 재고 -= e.출고건수 : 0
        e.입고건수 ? 재고 += e.입고건수 : 0
        const day = moment(e.date, 'YYYY-MM-DD').date()
        days[0][`${day}`] = e.출고건수
        days[1][`${day}`] = e.입고건수
        Vue.set(stock, day-1, 재고)
      })
    })

    this.days = days
    this.stock = stock
    this.$forceUpdate()
  }
  
  numbering(number): string {
    if(!number)
      return ''
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  headerSet() {
    this.day = moment(this.date, 'YYYY-MM').daysInMonth()
    const headers = [
      {
        text: '구분/일자', value: 'division',
        align: 'center',
        sortable: false,
        width: 90,
        class: 'font-weight-bold caption header-style',
      },
      {
        text: '직전', value: 'priv',
        align: 'center',
        sortable: false,
        width: 60,
        class: 'font-weight-bold caption header-style',
        divider: true
      },
    ]
    for (let index = 0; index < this.day; index++) {
      headers.push({
        text: `${index + 1}일`, value: `${index + 1}`,
        align: 'center',
        sortable: false,
        width: 60,
        class: 'font-weight-bold caption header-style',
      }) 
    }

    headers.push({
      text: '합계', value: 'total',
      align: 'center',
      sortable: false,
      width: 60,
      class: 'font-weight-bold caption header-style',
    })
    
    this.headers = headers
  }
  handleClick(value: any) {
    this.$emit('clickDate', this.date+'-'+value)
  }
  item: any = {selected: false}
  headers: any = []
  직전재고: number = 0

  day: number = 0
  days: any = []
  stock: any = []

  menu: boolean = false;
  date: String = Common.todayMonth(-1);
  today: String = Common.todayMonth(-1);

  async created(): Promise<void> {
    this.updateDate()
  }
}
