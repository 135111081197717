<template>
  <GChart :type="type" :data="data" :options="options" />
</template>

<script>
import { GChart } from 'vue-google-charts/legacy';

export const chartType = 'ColumnChart';

export const chartData = [
  ['지역', '전체수량', '재고(총판)', '설치완료'],
  ['서울특별시', 1000, 400, 200],
  ['부산광역시', 1170, 460, 250],
  ['대구광역시', 660, 1120, 300],
  ['인천광역시', 1030, 540, 350],
];

export const chartOptions = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017',
  },
  width: 700,
  height: 500,
};

export default {
  name: 'GoogleChart',
  components: {
    GChart,
  },
  data() {
    return {
      type: chartType,
      data: chartData,
      options: chartOptions,
    };
  },
};
</script>
