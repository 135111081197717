
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import moment from 'moment';

@Component({
  name: 'DepatureDetailVue',
  components: {
    Loading,
  },
})
export default class DepatureOutVue extends Vue {
  device_headers: object = [
  { text: '품목명', value: '제품명', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' ,
    },
  { text: '시리얼번호', value: '일련번호', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },
  { text: '출고처', value: '출고처.출고처_이름', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },
  { text: '상태', value: '상태', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' ,
    },

  ]
  checklist = [
  {
          action: 'mdi-bluetooth',
          items: [{ title: 'BLUETOOTH 불량', value: false }],
          title: 'BLUETOOTH',
        },
        {
          action: 'mdi-crosshairs',
          active: true,
          items: [{ title: 'GPS 인식 불량', value: false }],
          title: 'GPS 불량',
        },
        {
          action: 'mdi-rotate-orbit',
          items: [{ title: 'G센서 불량', value: false }],
          title: 'G센서 불량',
        },
        {
          action: 'mdi-credit-card-chip-outline',
          items: [
            { title: 'IC LED 불량', value: false },
            { title: 'IC CARD 인식 불량', value: false },
          ],
          title: 'IC 불량',
        },
        {
          action: 'mdi-led-variant-outline',
          items: [
            { title: 'LED 전광판 출력 불량', value: false },
            { title: 'LED 전광판 도트 불량', value: false },
          ],
          title: 'LED 불량',
        },
        {
          action: 'mdi-usb-flash-drive-outline',
          items: [{ title: '5PIN PORT 인식 불량', value: false }],
          title: 'Micro 5Pin USB 불량',
        },
        {
          action: 'mdi-sd',
          items: [
            { title: 'MICRO SD PORT 삽입 불량', value: false },
            { title: 'MICRO SD PORT 인식 불량', value: false },
          ],
          title: 'MICRO SD 불량',
        },
        {
          action: 'mdi-air-filter',
          items: [
            { title: 'MODEM1 불량', value: false },
            { title: 'MODEM2 불량', value: false },
            { title: 'MODEM 전원 불량', value: false },
          ],
          title: 'MODEM 불량',
        },
        {
          action: 'mdi-credit-card',
          items: [{ title: 'MSR 인식 불량', value: false }],
          title: 'MSR 불량',
        },
        {
          action: 'mdi-nfc-variant',
          items: [{ title: 'RF 인식 불량', value: false }],
          title: 'RF 불량',
        },
        {
          action: 'mdi-clock-outline',
          items: [{ title: 'RTC 불량', value: false }],
          title: 'RTC 불량',
        },
        {
          action: 'mdi-flag-outline',
          items: [{ title: 'SIG1 불량', value: false }],
          title: 'SIG1 불량',
        },
        {
          action: 'mdi-sim',
          items: [
            { title: 'SIM1 Socket 삽입 불량', value: false },
            { title: 'SIM1 Socket 인식 불량', value: false },
            { title: 'SIM2 Socket 삽입 불량', value: false },
            { title: 'SIM2 Socket 인식 불량', value: false },
          ],
          title: 'SIM Socket 불량',
        },
        {
          action: 'mdi-navigation-variant-outline',
          items: [{ title: '네비게이션 통신 불량', value: false }],
          title: '네비게이션 통신 불량',
        },
        {
          action: 'mdi-radiobox-marked',
          items: [
            { title: '설정 버튼 불량', value: false },
            { title: '빈차 버튼 불량', value: false },
            { title: '주행 버튼 불량', value: false },
            { title: '지불 버튼 불량', value: false },
            { title: '버튼 LED 불량', value: false },
            { title: '시외 버튼 불량', value: false },
          ],
          title: '버튼 불량',
        },
        {
          action: 'mdi-car-clutch',
          items: [{ title: '브레이크 불량', value: false }],
          title: '브레이크 불량',
        },
        {
          action: 'mdi-car-speed-limiter',
          items: [{ title: '속도/RPM 불량', value: false }],
          title: '속도/RPM 불량',
        },
        {
          action: 'mdi-printer-3d-nozzle',
          items: [
            { title: '영수증 인쇄 불량', value: false },
            { title: 'CASE 외관 불량', value: false },
            { title: 'PORT 외관 불량', value: false },
          ],
          title: '인쇄 불량',
        },
        {
          action: 'mdi-surround-sound',
          items: [{ title: '단말기 음성 불량', value: false }],
          title: '음성 불량',
        },
        {
          action: 'mdi-power-plug-outline',
          items: [
            { title: '5V 전원 불량', value: false },
            { title: '단말기 전원 불량', value: false },
            { title: 'ROOF1 전원 불량', value: false },
            { title: 'ROOF2 불량', value: false },
            { title: 'BINCHA 불량', value: false },
          ],
          title: '전원 불량',
        },
  ]
  devices: Array<object | null> = []
  isLoading: boolean = true
  
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  
  async created(): Promise<void> {
    const 단말정보 = await api.단말.FIND_AT_SERIAL(this.$route.query.id)
    const 단말기록 = (await api.departure.FIND_AT_SERIAL(this.$route.query.id)).data[0]
    console.log('단말정보:', 단말정보)
    this.devices = 단말정보.data
    this.devices[0].출고처 = 단말기록

    console.log('단말정보:', this.devices)

    this.isLoading = false
  }

  async registration() {
    this.isLoading = true

    let checklist: {중분류: string, 소분류: string}[] = []
    const reqInfo = this.devices[0]
    this.checklist.forEach(el => {
        el.items.forEach(element => {
          if(element.value){
            checklist.push({중분류: el.title, 소분류: element.title})
          }
        });
    })
    console.log(checklist)
    const res = await api.수리요청.REGISTER(reqInfo?.출고처.출고처_이름, reqInfo?.일련번호, this.userInfo.id, null, checklist )
    console.log("검수등록 ", res)
    if(res.code != 201){
      alert("검수 등록에 실패하였습니다.")
      window.close()
    } else {
      alert("검수 등록이 완료되었습니다.")
      window.close()
    }
    this.isLoading = true
  }
}
