
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import moment from 'moment';

@Component({
  name: 'DepatureDetailVue',
  components: {
    Loading,
  },
})
export default class DepatureOutVue extends Vue {
  device_headers: object = [
  { text: '품목명', value: '제품명', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' ,
      width: 300
    },
  { text: '시리얼번호', value: '일련번호', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },
  { text: '상태', value: '상태', align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' ,
      width: 300
    },

  ]
  history_headers: object = [
    {
      text: '입출고일',
      value: '생성일자',
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '구분', 
      value: '구분', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{
      text: '사용구분',
      value: '사용구분', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{
      text: '입고지역', 
      value: '출고처.지역', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '입고처', 
      value: '입고처_이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },
  ]
  
  fix_header: object = [
    {
      text: '상태',
      value: '상태',
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '일자', 
      value: '생성일자', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '특이사항', 
      value: '특이사항', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    }
  ]
  devices: Array<Object | null> = []
  history: Array<string | null> = []
  fix_history: Array<string | null> = []

  isLoading: boolean = true
  
  IPP: number = 5
  page: number = 1
  pageLength: number = 0

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }


  입출고일(생성일자) {
    return moment(생성일자).format('YYYY-MM-DD HH:mm:ss')
  }
  async created(): Promise<void> {
    const 단말정보 = await api.단말.FIND_AT_SERIAL(this.$route.query.id)
    console.log('단말정보:', 단말정보)
    this.devices = 단말정보.data

    const 입출고기록 = await api.departure.FIND_AT_SERIAL(this.$route.query.id)
    console.log(입출고기록.data)

    
    const res = await api.수리요청.FIND_AT_SERIAL(this.$route.query.id)
    console.log("수리요청:", res)
    this.fix_history = res.message

    this.history = 입출고기록.data
    this.pageLength = this.history.length/this.IPP

    // const id = res.data[0].출고담당자_id
    // const 출고처이름 = res.data[0].출고처_이름 == "인터패스" ? res.data[0].입고처_이름 : res.data[0].출고처_이름
    
    // this.구분 = res.data[0].출고처_이름 == "인터패스" ? "출고" : "입고"
    // this.사용구분 = res.data[0].사용구분
    // this.품목명 = res.data[0].단말.제품명
    // this.비고 = res.data[0].비고

    // res = await api.출고처.STORAGE_INFO(출고처이름)

    // this.parameters = res.data
    
    // console.log(this.parameters)

    
    // console.log(this.출고처)
    this.isLoading = false
  }
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: this.parameters.이름+" "+this.$route.query.id+" 입출고 내역",
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "입출고",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    let wsData = this.devices.map(el => {
      return {
        입출고일: this.$route.query.id,
        구분: this.구분,
        품목명: this.품목명,
        시리얼번호: el,
        사용구분: this.사용구분,
        업체명: this.parameters.이름,
        지역: this.parameters.지역,
        담당자: this.parameters.유저.이름,
        연락처: this.parameters.유저.전화번호,
        주소: this.parameters.주소,
        비고: this.비고
      }
    });
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), this.parameters.이름+'_'+this.$route.query.id+'_입출고_내역.xlsx');
    console.log(res)
  }

  async update(target: string) {
  }

}
