
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import Common from '@/utils/common';
import SummaryResult from './summary/index.vue';
import api from '@/api'

@Component({
  name: 'summary',
  components: {
    SummaryResult,
  },
})
export default class  DepartureListVue extends Vue {
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;
  tab = null
  items = [
    'KM100', 'KM200'
  ]

  expanded: any = [];
  data: Array<Object|null> = []

  async created(): Promise<void> {    
    this.$store.commit('MENU_ACTIVE', { value: 41 });
    this.$store.commit('SET_TITLE',  { title: '단말기 현황', icon: 'local_taxi' });

    this.isLoading = true
  }
}
