import { render, staticRenderFns } from "./DepartureTable.vue?vue&type=template&id=6a610625&scoped=true&"
import script from "./DepartureTable.vue?vue&type=script&lang=ts&"
export * from "./DepartureTable.vue?vue&type=script&lang=ts&"
import style0 from "./DepartureTable.vue?vue&type=style&index=0&id=6a610625&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a610625",
  null
  
)

export default component.exports