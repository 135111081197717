
import { Component, Vue } from 'vue-property-decorator';

import Loading from '@/layout/Loading.vue';
import ModalLoading from '@/layout/ModalLoading.vue';

@Component({
  name: 'App',
  components: {
    Loading,
    ModalLoading,
  },
})
export default class AppVue extends Vue {
  get isloading(): [object] {
    return this.$store.state.flags.isLoading;
  }
  get modalLoading(): [object] {
    return this.$store.state.flags.modalLoading;
  }
}
