
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';

@Component({
  name: 'DeviceResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class DeviceResultVue extends Vue {
  @Prop() headers: array;
  @Prop() data: array;
  @Prop() total!: number;

  @Watch('total')
  updateTotal() {
    this.pageLength = Math.ceil(this.total/this.IPP)
  }
  IPP: number = 15
  page: number = 1
  pageLength: number = 0

  search: String = '';

  async pageChanged(page: number): Promise<void> {
    this.page = page;
    this.$emit('pagination', this.page, this.IPP); 
  }
  
  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = Math.ceil(this.total/this.IPP)
    this.page = 1
    this.$emit('pagination', this.page, this.IPP); 
  }
  
  numbering(number): string {
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  async created(): Promise<void> {
    
    this.pageLength = Math.ceil(this.total/this.IPP)

    // await this.getDepartureList();
  }

  detail(item: any) {
    console.log(item)
    // this.$store.dispatch(Constant.Departure_DETAIL, { no, mode } );
    window.open("/#/device/detail?id="+item.일련번호, "PopupWin", "width=1000,height=945")

  }
  async searchBranch(item: any) {
  }
  async searchRoute(item: any) {
  }
  async searchVehicle(item: any) {
  }
}
