
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import Approval from './approval.vue';
import Hold from './hold.vue';
import Cancel from './cancel.vue';

import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import Receipt from './receipt.vue';

@Component({
  name: 'RequestDetailVue',
  components: {
    Loading,
    Approval,
    Receipt,
    Hold,
    Cancel,
  },
})
export default class RequestDetailVue extends Vue {
  history_headers: object = [
    {
      text: '상태',
      value: '상태',
      align: 'center',
      sortable: false,
      class: 'font-weight-bold caption header-style white--text'
    }, {
      text: '결재일',
      value: '생성일자',
      align: 'center',
      sortable: false,
      class: 'font-weight-bold caption header-style white--text'
    }, {
      text: '담당자',
      value: '유저.이름',
      align: 'center',
      sortable: false,
      class: 'font-weight-bold caption header-style white--text'
    }, {
      text: '특이사항',
      value: '특이사항',
      align: 'center',
      sortable: false,
      class: 'font-weight-bold caption header-style white--text'
    }
  ]
  APP_FILE = process.env.VUE_APP_FILE
  request: Object = {}
  출고일: String = ''
  history: Array<string | null> = []
  isLoading: boolean = true

  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  get 요청수량(): String {
    return this.request?.수량.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }
  get 승인수량(): String {
    return this.request?.승인수량.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  입출고일(생성일자) {

    return 생성일자 ? moment(생성일자).format('YYYY-MM-DD HH:mm:ss') : ''
  }
  async created(): Promise<void> {
    const 요청정보 = await api.출고요청.FIND_AT_TIME(this.$route.query.id)
    this.request = 요청정보.message[0]
    this.history = this.request.출고요청기록
    this.출고일 = this.입출고일(this.request.입출고_기록)
    console.log(this.request)

    this.isLoading = false
  }

  async update(params: string): Promise<void> {
    this.isLoading = true

    switch (params) {
      case '승인완료':
      case '승인보류':
        var result = confirm("출고요청을 승인하시겠습니까?");
        if (result) {
          const res = await api.출고요청.APPROVAL(this.$route.query.id, this.userInfo.id, params)
          alert(res.message)
        } else {

        }
        break;

      case '출고완료':
        const Win = await window.open("/#/departure/out?id=" + this.$route.query.id, "DeparturePopupWin", "width=1205,height=530")
        const it = this
        Win.onunload = async function () {
          it.refresh()
        }
        break;

      default:
        break;
    }

    const 요청정보 = await api.출고요청.FIND_AT_TIME(this.$route.query.id)
    this.request = 요청정보.message[0]
    this.history = this.request.출고요청기록
    console.log(this.request)

    this.isLoading = false
  }
  async refresh(): Promise<void> {
    this.isLoading = true

    const 요청정보 = await api.출고요청.FIND_AT_TIME(this.$route.query.id)
    this.request = 요청정보.message[0]
    this.history = this.request.출고요청기록
    console.log(this.request)

    this.isLoading = false
  }
  async 인수증(params: string): Promise<void> {
    const response = await api.출고요청.DOWNLOAD(params)
    const fileName = params.split('/').pop()

    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url;
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.parentNode.removeChild(link)

    // window.open(process.env.VUE_APP_FILE + params, "인수증")
    // window.close();
  }
}
