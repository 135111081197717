import auth from './auth';
import departure from './departure';
import 출고처 from './출고처';
import 단말 from './단말';
import 차량 from './차량';
import 출고요청 from './출고요청';
import 수리요청 from './수리요청';
import 요약 from './요약';
import tims from './tims';
import SMS from './SMS';

export default { auth, departure, 출고처, 단말, 차량, 출고요청, 수리요청, 요약, tims, SMS }