
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component({
  name: 'Default',
  components: {
  },
})
export default class DefaultVue extends Vue {
  async created() {
    console.log('Default')
    const token = localStorage.accessToken;
    // await this.$store.dispatch('CHECK_AUTH', { token, routerName: null } )
    // this.$store.commit('MENU_ACTIVE', { value: 0 }); //state 값의 +1
    //this.$store.commit('SET_TITLE', { title: 'U2000', icon: 'find_in_page' });
  }


}
