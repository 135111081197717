
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import DepartureResult from '@/views/departure/DepartureResult.vue';
import moment from 'moment';
import Common from '@/utils/common';

import Calendar from '@/components/common/Calendar.vue';
import api from '@/api'

@Component({
  name: 'summary',
  components: {
    DepartureResult,
    Calendar,
  },
})
export default class  SummaryVue extends Vue {
  get userInfo(): any {
    return this.$store.getters.getUser
  }

  tab = null
  items = [
    'KM100', 'KM200'
  ]
  
  isLoading: any = false;
  data: Array<Object|null> = []
    date: Object = {from: Common.todayDate(0), to: Common.todayDate(1)};

  expanded: any = [];
  async created(): Promise<void> {    
    this.$store.commit('MENU_ACTIVE', { value: 31 });
    this.$store.commit('SET_TITLE',  { title: '재고 현황', icon: 'summarize' });
    this.isLoading = true
  }

  async selectedDate(date: any): Promise<void> {
    this.date['from'] = moment(date).format('YYYY-MM-DD')
    this.date['to'] = moment(date).add(1, 'days').format('YYYY-MM-DD')
    console.log("date: ", this.date)
  }
}
