
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';

import { MDN, CITY, FARE, VAN } from '../interface'

@Component({
  name: 'TaxiDetailVue',
  components: {
    Loading,
  },
})

export default class TaxiDetailVue extends Vue {

  driver: Array<boolean> = [false, false, false]

  item: MDN = {
    mdn: '',
    car_vin: '00000000000000000',
    car_type: 21,
    car_num: '',
    car_regnum: '',
    company_name: '',
    driver_id1: '111111111',
    driver_id2: '222222222',
    driver_id3: '333333333',
    vfare_use: false,
    fare_id: 0,
    city_id: 0,
    firmware_id: 0,
    firmware_update: true,
    daemon_update: true,
    speed_factor: 5120,
    daemon_id: 1,
    konaiMid: '',
    konaiTid: '',
    rpm_factor: 2000,
    status: '미설치'
  }

  rules = {
    required: (value: string) => !!value || '필수 입력 항목입니다.',
    size8: (v: string) => v.length == 8 || '자릿수가 맞지 않습니다.',
    size9: (v: string) => v.length == 9 || '자릿수가 맞지 않습니다.',
    size10: (v: string) => v.length == 10 || '자릿수가 맞지 않습니다.',
    size11: (v: string) => v.length == 11 || '자릿수가 맞지 않습니다.',
    size15: (v: string) => v.length == 15 || '자릿수가 맞지 않습니다.',
    size17: (v: string) => v.length == 17 || '자릿수가 맞지 않습니다.',
    number: (v: string) => !isNaN(Number(v)) || '숫자만 입력해주세요.',
    // format 가가99가9999 (가: 한글, 9: 숫자)
    car_num: (v: string) => /^([가-힣]{2})([0-9]{2})([가-힣]{1})([0-9]{4})$/.test(v) || '형식에 맞게 입력해주세요.',

  }
  city: CITY[] = []
  fare: Array<FARE> = []
  van: Array<VAN> = []
  car_num: string = ''

  verify: boolean = false

  isLoading: boolean = true

  get carNumChanged() {
    return this.car_num != this.item.car_num
  }

  async created(): Promise<void> {
    console.log(this.$route.query)
    let res = await api.차량.SHOW_ALL({mdn: this.$route.query.id})
    const TAXI = res.data[0]
    const CITY = await api.차량.CITY()
    const FARE = await api.차량.FARE()
    const VAN = await api.차량.VAN()
    this.city = CITY.cityExists
    this.fare = FARE.fareExists
    this.van = VAN.vanExists
    this.car_num = TAXI.car_num

    this.item = TAXI;

    this.isLoading = false
  }

  async save(target: string) {
    this.isLoading = true
    console.log(this.item)
    let res = await api.차량.UPDATE(
      this.$route.query.id,
      this.item.mdn == this.$route.query.id ? undefined : this.item.mdn,
      this.item.car_vin,
      this.item.car_type,
      this.verify ? this.item.car_num : undefined,
      this.item.car_regnum,
      this.item.company_name,
      this.verify ? this.item.driver_id1 : undefined,
      this.verify ? this.item.driver_id2 : undefined,
      this.verify ? this.item.driver_id3 : undefined,
      this.item.vfare_use,
      this.item.fare_id,
      this.item.city_id,
      this.item.daemon_id,
      this.item.firmware_id,
      Number(this.item.speed_factor),
      Number(this.item.rpm_factor),
      this.item.daemon_update,
      this.item.firmware_update,
      this.item.status
    )
    if (res.status == 200) {
      console.log(res.body)
      alert('저장되었습니다.')
      // window.close()
    } else if (res.code == 409) {
      alert('이미 등록된 차량입니다.')
    } else {
      alert('알 수 없는 에러가 발생했습니다.')
    }
    this.isLoading = false
  }
  
  verifyDriver() {
    console.log("verifyDriver")
    const CAR_REG_NO = this.item.car_num
    Promise.all(this.driver.map(async (enable, index) => {
      if (enable) {
        const res = await api.tims.AUTH(CAR_REG_NO, this.item[`driver_id${index + 1}`])
        console.log(res)
        let code_str = "알 수 없는 에러가 발생했습니다."

        if (res.status == 200) {
          return true
        } else {
          alert(res.response.data.message);
          return false
        }
      }
      return true
    })).then((res) => {
      if (res.every(el => el == true)) {
        this.verify = true
      }
    })
  }

}
