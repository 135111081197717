import Vue from 'vue';
import Vuex from 'vuex';

import flags from './flags';
import auth from './auth';
import menu from './menu';
// import as from './module/AS/index';
// import stock from './module/stock/index';
// import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    // createPersistedState({
    //   // storage: window.sessionStorage
    // })
  ],
  modules: {
    flags,
    auth,
    menu,
    // as,
    // stock,
  },
});
