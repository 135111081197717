
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { CITY } from '../interface';

@Component({
  name: 'TaxiUserVue',
  components: {
    Loading,
  },
})

export default class TaxiUserVue extends Vue {
  headers: object = [
    { text: '지역', align: 'center', sortable: false, value: 'city_name', class: "white--text", },
    { text: '권한', align: 'center', sortable: false, value: 'role_name', class: "white--text", },
    { text: 'ID', align: 'center', sortable: false, value: 'id', class: "white--text", },
    { text: '이름', align: 'center', sortable: false, value: 'name', class: "white--text", }
  ]
  selectedRow: Array<Object | null> = []
  isLoading: boolean = true
  search: string = ''

  비밀번호: string = '';
  권한 = null;
  지역 = null;
  이름: string = "";
  ID: string = "";

  city: CITY[] = []

  role = {
    4: "전체",
    5: "개인",
    6: "법인",
  }

  userList: any
  showP: boolean = false;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0


  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  async created(): Promise<void> {
    this.$store.commit('MENU_ACTIVE', { value: 11 });
    this.$store.commit('SET_TITLE', { title: '유저 관리', icon: 'manage_accounts' });
    this.getUserList()
    this.isLoading = false
  }

  async getUserList() {
    this.city = (await api.차량.CITY()).cityExists
    this.userList = (await api.차량.USER_SHOW_ALL()).data.map(el => {
      return {
        ...el,
        city_name: this.city.find(city => city.city_id == el.city_id)?.city_name,
        role_name: this.role[el.role]
      }
    })
    console.log("user list:", this.userList)
    this.pageLength = Math.ceil(this.userList.length / this.IPP)
  }

  async registration(target: string) {
    
    if ((this.이름 == '' || this.이름 == null) ||
      (this.권한 == '' || this.권한 == null) ||
      (this.ID == '' || this.ID == null) ||
      (this.지역 == '' || this.지역 == null) ||
      (this.비밀번호 == '' || this.비밀번호 == null)) {
      alert("입력되지 않은 값이 있습니다.")
      return
    }
    this.isLoading = true

    const res = await api.차량.USER_REGISTER(
      this.ID,
      this.비밀번호,
      this.이름,
      this.지역?.city_id,
      this.권한?.id
    )
    console.log(res)
    
    if (res.code == 200) {
      alert("등록 성공")
      this.이름 = ""
      this.권한 = null
      this.ID = ""
      this.지역 = null
      this.비밀번호 = ""
      this.getUserList()
    } else {
      alert("등록 실패")
    }
    this.userList = (await api.차량.USER_SHOW_ALL()).data.map(el => {
      return {
        ...el,
        city_name: this.city.find(city => city.city_id == el.city_id)?.city_name,
        role_name: this.role[el.role]
      }
    })
    console.log("user list:", this.userList)
    this.pageLength = Math.ceil(this.userList.length / this.IPP)

    this.isLoading = false
    this.$forceUpdate();
  }

}
