
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import api from '@/api'
import common from '@/utils/common';

@Component({
  name: 'DepartureResult',
})

export default class DepartureResultVue extends Vue {
  @Prop() 품목명!: string;

  taxiHeaders = [
    { text: '지역', value: '지역', align: 'center', width: '10%', sortable: false },
    { text: '법인', value: '법인', align: 'center', width: '10%', sortable: false },
    { text: '개인', value: '개인', align: 'center', width: '10%', sortable: false },
    { text: '법인(전기)', value: '법인(전기)', align: 'center', width: '10%', sortable: false },
    { text: '개인(전기)', value: '개인(전기)', align: 'center', width: '10%', sortable: false },
  ];
  taxiData = [];
  IPP = 10;

  async created(): Promise<void> {
    const taxiData = await api.차량.DASHBOARD(this.품목명)

    let taxiBody: Array<{
      지역: string,
      법인: number,
      개인: number,
      '법인(전기)': number,
      '개인(전기)': number,
      수량: number,
    }> = []

    let depBody: Array<{
      지역: string,
      수량: number,
    }> = []

    taxiData.data.forEach(element => {
      const index = taxiBody.findIndex(e => e.지역 === element.지역)
      if (index != -1) {
        taxiBody[index][common.차량유형리스트()[element.구분]] = element.수량
      } else {
        let taxi = {
          지역: element.지역,
          법인: 0,
          개인: 0,
          '법인(전기)': 0,
          '개인(전기)': 0,
          수량: 0,
        }
        taxi[common.차량유형리스트()[element.구분]] = element.수량
        taxiBody.push(taxi)
      }
    });

    console.log(taxiBody)
    console.log(depBody)
    this.taxiData = taxiBody as any;
    this.IPP = this.taxiData.length
  }
  
}
