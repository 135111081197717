
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import Common from '@/utils/common';
import DepartureInput from './DepartureInput.vue';
import DepartureResult from './DepartureResult.vue';

@Component({
  name: 'DepartureList',
  components: {
    DepartureInput,
    DepartureResult,
  },
})
export default class  DepartureListVue extends Vue {
  @Prop() model!: string;

  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;
  date: Object = {to: Common.todayDate(-1), from: Common.todayDate(30)};
  filter: Object = {};
  today: any = Common.todayDate(-1);

  menu1: boolean = false;
  menu2: boolean = false;

  expanded: any = [];

  async openPopup(path: string): Promise<void> {
    const DeparturePopupWin = await window.open("/#/departure/"+path, "DeparturePopupWin", "width=1205,height=530")
    const it = this
    DeparturePopupWin.onunload = async function() {
      console.log("close")
    }
    // popup.addEventListener('beforeunload', function() {
    //   console.log("close")
    //   it.created()
    // });

  }

  async changeFilter(filter: any): Promise<void> {
    console.log("filter = ", filter)
    let buffer = {}
    buffer.출고담당자_id = filter.담당자?.id ?? undefined
    buffer.입고처_이름 = filter.입고처_이름 ?? undefined
    buffer.출고처_이름 = filter.출고처_이름 ?? undefined
    buffer.사용구분 = filter.사용구분 != "ALL" ? filter.사용구분 : undefined

    this.filter = buffer
  }

  async created(): Promise<void> {
    // console.log(this.userInfo.id)

    this.$store.commit('MENU_ACTIVE', { value: 32 });
    this.$store.commit('SET_TITLE',  { title: '입·출고 관리', icon: 'archive' });

    this.isLoading = true
  }
}
