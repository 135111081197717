import axios, { AxiosError } from 'axios';
// import qs from 'qs'
const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true
import router from '@/router';
import Class from './class';
import v1 from './v1_req';
import register from './register_req';
import user from './user';

export default {
  ...Class,
  ...v1,
  ...register,
  ...user
}  