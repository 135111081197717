import { MutationTree } from 'vuex';
import { State } from './state';
//import axios from "axios";

const mutation: MutationTree<State> = {
  //로그인 실패
  LOGIN_FAILED: (state: State, payload: any): void => {
    state.errorMessage = payload.errorMessage;
  },
  SET_USER: (state: State, payload: any): void => {
    console.log("mutations:SET_USER", payload);
    sessionStorage.setItem("user", JSON.stringify(payload));
  },
  UNSET_USER: (state: State, payload: any): void => {
    console.log("mutations:UNSET_USER", payload);
    sessionStorage.removeItem("user");
  },
};

export default mutation;