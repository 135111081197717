
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';
import api from '@/api'

import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';

@Component({
  name: 'DepartureResult',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class DepartureResultVue extends Vue {
  @Prop() 품목명!: string;
  @Prop() date!: Object;
  @Prop() filter!: Object;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0
  length: number = 0
  isLoading: boolean = false;
  data: any;
  headers: Array<Object> = [{ 
      text: '입출고일', 
      value: '생성일자', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '구분', 
      value: '구분', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '품목명', 
      value: '단말.제품명', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '사용구분', 
      value: '사용구분', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '입/출고 수량', 
      value: 'count', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '현재고', 
      value: '현재고', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '지역', 
      value: '출고처.지역', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '입출고처', 
      value: '입고처이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '비고', 
      value: '비고', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '',
      value: 'action', 
      align: 'center', 
      sortable: false, 
      width: 80,
      class: 'font-weight-bold caption header-style' 
    },
  ];
  @Watch('data')
  updateTable() {
    this.changeIPP(this.IPP)
  }

  @Watch('filter', { immediate: true, deep: true })
  @Watch('date', { immediate: true, deep: true })
  async updateDate() {
    console.log("update", this.filter)
    const departureRes = await api.departure.FIND_AT_FILTER({num: this.page, limit: this.IPP}, this.date, this.filter, this.품목명)
    const length = await api.departure.COUNT({생성일자: this.date, ...this.filter}, this.품목명)
    this.length = length.data
    // const storageRes = await api.출고처.STORAGE_INFO("인터패스")
    const storageRes = await api.단말.TOTAL({소유지: "인터패스", 제품명: this.품목명})
    console.log("res:", this.length)
    let dataArray = departureRes.data
    this.data = []
    if(dataArray.length == 0){    
      this.$forceUpdate();

      this.isLoading = true
      return
    }
    
    this.data = await dataArray.map((element, index, org) => {
      element.구분 = (element.입고처_이름 == '인터패스'?true:false)
      element.입고처이름 = (element.입고처_이름 == '인터패스'?element.출고처_이름:element.입고처_이름)
      return element
    })
    console.log("data: ", this.data)
    this.data[0].현재고 = storageRes.data
    this.data.map((el, index) => {
      index==0 ? 
        null :  
        this.data[index].현재고 = 
          this.data[index-1].구분 ?
            (this.data[index-1].현재고 - this.data[index-1].count):
            (this.data[index-1].현재고 + this.data[index-1].count)
    })
    console.log("data = ", this.data)
    this.pageLength = Math.ceil(this.length/this.IPP)
    this.$forceUpdate();
    }
  
  입출고일(date: string) {
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
  }
  async pageChanged(page: number): Promise<void> {
    this.page = page;
    
    this.updateDate()
  }
  async changeIPP(IPP: number): Promise<void> {
    this.IPP = IPP;
    this.pageLength = Math.ceil(this.data.length/this.IPP)
    this.page = 1
    this.updateDate()
  }
  numbering(number): string {
    if(!number)
    return '0'
    console.log(number)
    return number.toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }

  detail(item: any) {
    console.log(item)
    // this.$store.dispatch(Constant.Departure_DETAIL, { no, mode } );
    window.open("/#/departure/detail?id="+item.생성일자, "DepartureDetailPopupWin", "width=1000,height=544")

  }
  인수증(item: any) {
    console.log(item)
    // this.$store.dispatch(Constant.Departure_DETAIL, { no, mode } );
    window.open("/#/document/receipt?id="+item.생성일자, "PopupWin", "width=1280,height=940")

  }
  
  async created(): Promise<void> {
    console.log('test')
    const departureRes = await api.departure.FIND_AT_FILTER({num: this.page, limit: this.IPP}, this.date, {}, this.품목명)
    const length = await api.departure.COUNT({생성일자: this.date, ...this.filter}, this.품목명)
    this.length = length.data
    // const storageRes = await api.출고처.STORAGE_INFO("인터패스")
    const storageRes = await api.단말.TOTAL({소유지: "인터패스", 제품명: this.품목명})
    console.log("res:", this.length)
    let dataArray = departureRes.data
    
    if(!dataArray?.length){    
      this.isLoading = true
      return
    }
    
    this.data = await dataArray.map((element, index, org) => {
      element.구분 = (element.입고처_이름 == '인터패스'?true:false)
      element.입고처이름 = (element.입고처_이름 == '인터패스'?element.출고처_이름:element.입고처_이름)
      return element
    })
    console.log("data: ", this.data)
    this.data[0].현재고 = storageRes.data
    this.data.map((el, index) => {
      index==0 ? 
        null :  
        this.data[index].현재고 = 
          this.data[index-1].구분 ?
            (this.data[index-1].현재고 - this.data[index-1].count):
            (this.data[index-1].현재고 + this.data[index-1].count)
            console.log(el)
            console.log(index)
    })
    console.log("data = ", this.data)
    this.pageLength = Math.ceil(this.length/this.IPP)
    this.$forceUpdate();
    this.isLoading = true
  }
  
  s2ab(s) {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    return buf;
  }
  async writeXLSX(): Promise<void> {
    let wb = XLSX.utils.book_new();

    // 문서 속성세팅 ( 윈도우에서 엑셀 오른쪽 클릭 속성 -> 자세히에 있는 값들
    wb.Props = {
        Title: "입출고 내역",
        Subject: "",
        Author: "InterPass",
        Manager: "김어진",
        Company: "InterPass",
        Category: "ERP Data",
        Keywords: "입출고",
        Comments: "",
        LastAuthor: "",
        CreatedDate: new Date()
    };
    wb.SheetNames.push("sheet 1");
    const departureRes = await api.departure.FIND_AT_FILTER({num: 1, limit: this.length}, this.date, {}, this.품목명)

    const wsData = await departureRes.data.map((element, index, org) => {
      return {
        입출고일: moment(element.생성일자).local().format('YYYY-MM-DD HH:mm:ss'),
        담당자: element.유저.이름,
        구분: element.입고처_이름 == '인터패스'?"입고":"출고",
        품목명: element.단말.제품명,
        사용구분: element.사용구분,
        "입/출고 수량": element.count,
        현재고: element.현재고,
        지역: element.출고처.지역,
        입출고처: element.입고처_이름 == '인터패스'?element.출고처_이름:element.입고처_이름,
        비고: element.비고
      }
    })
    console.log(wsData)
    // wsData.unshift(["품목명", "사용구분", "출고처", "지역", "담당자", "연락처", "주소", "비고"]);
    const ws = XLSX.utils.json_to_sheet(wsData);
    wb.Sheets["sheet 1"] = ws;
    const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
    console.log("download")
    const res = saveAs(new Blob([this.s2ab(wbout)],{type:"application/octet-stream"}), '입출고내역.xlsx');
    console.log(res)
  }
}
