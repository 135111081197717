import { RouteConfig } from 'vue-router';

import store from '@/store';
import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';

import StorageRegister from '@/views/storage/popup/StorageRegister.vue';
import StorageList from '@/views/storage/StorageList.vue';

import Common from '@/utils/common';

export const storageRoutes: RouteConfig[] = [
    {
        path: '/storage',
        name: 'storage',
        components: {
            header: Header,
            menu: Menus,
            content: StorageList,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/storage/register',
        name: 'storageRegister',
        components: {
            content: StorageRegister,
        },
        beforeEnter: (to, from, next) => {
            document.title = "대상 등록";
            Common.requireAuth(to, from, next, null)
        },
    },
    // ...ledger,
    // ...doc,
];

