
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {
  get devicesWithIndex() {
    return this.devices.map(
      (items, index) => ({
        일련번호: items,
        no: index + 1
      }))
  }
  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  get 지역() {
    return this.parameters != null ? this.parameters.지역 : ''
  }
  get 주소() {
    return this.parameters != null ? this.parameters.주소 : ''
  }
  get 담당자() {
    return this.parameters != null ? this.parameters.유저.이름 : ''
  }
  get 연락처() {
    return this.parameters != null ? this.parameters.유저.전화번호 : ''
  }

  headers: object = [
    { text: 'No', value: 'no', class: "white--text" },
    { text: '시리얼번호', value: '일련번호', class: "white--text", },
  ]
  selectedRow: Array<Object | null> = []
  devices: Array<string | null> = []
  isLoading: boolean = true

  출고처: Array<Object> = [];
  일련번호: string = '';
  비고: string | null = null;
  사용구분: string = "회수";
  품목명: string = "KM100";
  parameters: Object|null = null;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0

  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  async created(): Promise<void> {
    const res = await api.출고처.SHOW_ALL()
    this.출고처 = res.data.filter((el) => el.분류 == "납품사")
    console.log(this.출고처)
    this.isLoading = false
  }

  addDevice() {
    if (this.일련번호 == '' || isNaN(Number(this.일련번호))) {
      alert('일련번호를 입력해주십시오')
      return
    }
    const result = this.devices.filter(el => el == this.일련번호);
    console.log(result)
    if (0 != result.length) {
      alert('중복된 일련번호 입니다.')
      return
    }

    this.devices.push(this.일련번호)
    this.일련번호 = ''
    this.pageLength = Math.ceil(this.devices.length/this.IPP)

  }
  readFile(event) {
    // get File object from input tag
    const file = event.target.files[0];
    // const fileName = file.name;

    // declare FileReader, temp result
    const reader = new FileReader();
    let tmpResult = {};

    // if you use "this", don't use "function(e) {...}"
    reader.onload = (e) => {
      let data = e.target.result;
      data = new Uint8Array(data);
      // get excel file
      let excelFile = XLSX.read(data, { type: "array" });

      // get prased object
      excelFile.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(
          excelFile.Sheets[sheetName],
          { header: 1 }
        );
        if (roa.length) tmpResult[sheetName] = roa;
      });
      tmpResult.Sheet1.forEach(el => {
        if (el[0] == '' || isNaN(Number(el[0]))) {
          alert('파싱에 실패하였습니다.')
          return
        }
        const result = this.devices.filter(element => element == el[0]);
        if (0 != result.length) {
          alert('중복된 일련번호가 있습니다.')
          return
        }

        this.devices.push(el[0])
        this.pageLength = Math.ceil(this.devices.length/this.IPP)
      });
      this.isLoading = false
    };

    reader.readAsArrayBuffer(file);
  }
  onDeleteButtonClick() {
    this.selectedRow.forEach(el => {
      console.log("row: ", el)
      this.devices = this.devices.filter(function(item) {
        return item !== el.일련번호
      });
    });
    this.selectedRow = []
  }
  onReadFileButtonClick() {
    this.isLoading = true
    window.addEventListener('focus', () => {}, { once: true })

    this.$refs.uploader.click()
  }
  
  async registration(target: string) {
    this.isLoading = true
    if (this.parameters != null && this.devices.length != 0) {
      const res = await api.departure.REGISTER(
        this.품목명,
        this.devices,
        this.비고,
        this.사용구분,
        this.parameters.이름,
        '인터패스',
        this.userInfo.id,
      )
      console.log(res)
      this.isLoading = false
      if (res.code == 201){
        alert(res.message)
        window.close()
      } else {
        alert(res.message)
      }
    } else {
      alert("입력되지 않은 값이 있습니다.")
    }
    this.isLoading = false
  }

}
