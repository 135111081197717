
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
// import BranchCheckList from './branch/BranchCheckList.vue';
// import PhotoList from './branch/PhotoList.vue';
import printJS from 'print-js';
import Constant from '@/utils/constant';
import api from '@/api'

import moment from 'moment';

@Component({
  name: 'receiptDocument',
  components: {
    // BranchCheckList,
    // PhotoList,
  },
})
export default class AggDocumentModalVue extends Vue {

  devices: Array<Array<Object<{
    시리얼번호: string, 
    품명: string, 
    수량: string
  }>> | null> = [
    [
      {
        시리얼번호: "20220800001",
        품목명: "KM100",
        수량: "1"
      }
    ]
  ]
  
  
  out_info: Object<{
    상호명: string,
    담당자: string,
    연락처: string,
  }> = {
    상호명: "",
    담당자: "",
    연락처: "",
  }
  in_info: Object<{
    상호명: string,
    담당자: string,
    연락처: string,
    주소: string,
  }> = {
    상호명: "",
    담당자: "",
    연락처: "",
    주소: "",
  }

  특이사항: string = ""
  작성일자: string = ""
  합계: string = ""

  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  async created() {
    let res
    res = await api.departure.FIND_AT_TIME(this.$route.query.id)
    console.log("입출고: ", res)
    const 품명 = res.data[0].단말.제품명
    const 출고처_이름 = res.data[0].출고처_이름
    const 입고처_이름 = res.data[0].입고처_이름
    const data = res.data.map((el, index) => {
      return{
        시리얼번호: el.일련번호,
        품명,
        수량: "1"
      }
    })

    this.특이사항 = res.data[0].비고
    this.작성일자 = moment(res.data[0].생성일자).format('YYYY-MM-DD')
    console.log(res)

    this.합계 = data.length

    if(data.length <= 30) {
      this.devices[0] = data.concat(Array.from({length:30-data.length}, ()=>{return {
        시리얼번호: "",
        품명: "",
        수량: ""
      }}))
    } else if (data.length <= 40) {
      this.devices[0] = data.concat(Array.from({length:40-data.length}, ()=>{return {
        시리얼번호: "",
        품명: "",
        수량: ""
      }}))
    } else {
      this.devices[0] = data.slice(0, 40)
      for (let index = 0; 0 < (data.length - 40) - (50*index); index++)
        this.devices[index+1] = data.slice((index*50)+40, (index*50+50)+40)
    }

    this.out_info = {
      상호명: 출고처_이름,
      담당자: this.userInfo.이름,
      연락처: this.userInfo.전화번호,
    }
    res = await api.출고처.STORAGE_INFO(입고처_이름)
    this.in_info = {
      상호명: res.data.이름,
      담당자: res.data.유저.이름,
      연락처: res.data.유저.전화번호,
      주소: res.data.주소,
    }
  }

  currentTitle:string = ''

  PDF_Down(){
    this.currentTitle= document.title;
    document.title = `${new Date().getFullYear().toString()}_${(new Date().getMonth() +1).toString()}_${new Date().getDate().toString()}_${this.in_info.상호명}`
    console.log(document.title)
      printJS({
      printable:'doc', // 프린트 만들 엘리먼트 id
      showModal:true, //로딩 창을 모달로
      type: 'html', // html -> pdf
      scanStyles:false, // default true.  true이면 print js 라이브러리 css를 따름. false로 해야 custom css 먹힘
      // css ex:   ['./server static css url (localhost/print.css)']
      //style issue: string 변수로 집어 넣으면 안먹히고 인라인으로 직접 넣어야 하는 이슈가 있음. 이유모름
      //style과 css는 다른 프로퍼티임   
      style:`
      @media print {
        @page 
        {
            size: A4 portrait;    /* auto is the initial value */
            margin: 10mm 3mm 0mm 0mm;  /* this affects the margin in the printer settings */
        }
        
  .root {
    page-break-after: always;
    -webkit-print-color-adjust: exact; 
  }
}
.root{
  width: 1000px;
}

.logo{
  width:200px;
  height:auto;
  border: 0px;
}

.input{
          border-style: none;
        }
        input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }

.title {
  text-align: center;
}
.header {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
  margin-top: 20px;
}
.header > .install span{
  margin-left: 20px;
  margin-right: 20px;
}
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
.rowspan {
  text-align: center;
}
.check_list {
  text-align: center;
  color: #757575;
  caret-color:  #757575;
}
.manager_confirm {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 80px;
}
.pd_name {
  letter-spacing: 10px;
  text-indent: 10px;
}
.inventory {
  line-height: 30px;
  letter-spacing: 10px;
  text-align:center;
}
.stock {
  word-spacing: 20px;
}
.inven_table {
  border: none !important;
}
.inven_table> thead {
  background-color:#E9EDF7;
}
      `
    });
  }
  beforeDestroy(){
    document.title = this.currentTitle
  }
}
