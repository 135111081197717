
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import moment from 'moment';
import api from '@/api'

import Common from '@/utils/common';
import DepartureInput from './DepartureInput.vue';
import DepartureResult from './DepartureResult.vue';

@Component({
  name: 'DeviceList',
  components: {
    DepartureInput,
    DepartureResult,
  },
})
export default class  DeviceListVue extends Vue {
  @Prop() model!: string;

  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  isLoading: any = false;
  date: Object = {to: Common.todayDate(-1), from: Common.todayDate(30)};
  filter: Object = {};
  today: any = Common.todayDate(-1);

  menu1: boolean = false;
  menu2: boolean = false;

  tab = null
  items = [
    'KM100', 'KM200'
  ]
  
  expanded: any = [];
  headers: Array<Object> = [{ 
      text: '입출고일', 
      value: '생성일자', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '품목명', 
      value: '단말.제품명', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '시리얼번호', 
      value: '일련번호', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '사용구분', 
      value: '사용구분', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '출고처', 
      value: '출고처_이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '구분', 
      value: '구분', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '입고처', 
      value: '입고처_이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },{ 
      text: '담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style' 
    },
  ];
  
  async changeFilter(filter: any): Promise<void> {
    console.log("filter = ", filter)
    let buffer = {}
    buffer.출고담당자_id = filter.담당자?.id ?? undefined
    buffer.입고처_이름 = filter.입고처_이름 ?? undefined
    buffer.출고처_이름 = filter.출고처_이름 ?? undefined
    buffer.일련번호 = filter.일련번호 ?? undefined
    buffer.사용구분 = filter.사용구분 != "ALL" ? filter.사용구분 : undefined

    this.filter = buffer
  }

  async created(): Promise<void> {
    // console.log(this.userInfo.id)
    
    this.$store.commit('MENU_ACTIVE', { value: 33 });
    this.$store.commit('SET_TITLE',  { title: '입·출고 상세', icon: 'vibration' });

    this.isLoading = true
  }
}
