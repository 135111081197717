
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';
import api from '@/api'

@Component({
  name: 'DepartureInput',
  components: {},
})
export default class DepartureInputVue extends Vue {
public parameters: object = {
  단말: {
    제품명: 'ALL',
    상태: 'ALL',
    소유지: undefined,  
  },
  출고처: {
    지역: undefined,
  }
}
private 담당자: Array<string> = []
private 지역: Array<string> = []
private 소유지: Array<string> = []

  
  get 모델명리스트() {
    return ['ALL', ...Common.모델명리스트()]
  }
  

  async created(): Promise<void> {
    const res = await api.출고처.SHOW_ALL()
    console.log(res)
    this.소유지 = res.data.map(el => el.이름)
    this.지역 = res.data.map(el => el.지역)

  }


  async search(): Promise<void> {
    // this.DepartureList.page = 1;
    // await this.$store.dispatch(Constant.Departure_LIST, {});
    this.$emit('searchEvent', this.parameters); 
  }

  //초기화
  async inputReset(): Promise<void> {
    this.parameters = {
      제품명: 'ALL',
      상태: 'ALL',
      지역: undefined,
      소유지: undefined,
    }
    this.$emit('searchEvent', this.parameters); 
  }

  changeVal(item: any, target: string) {
    if(typeof(item) == 'string'){
      this.parameters[target] = new Object();
      this.parameters[target].no = 0;
      this.parameters[target].id = "ALL";
      this.parameters[target].name = "전체";
    }
  }
}
