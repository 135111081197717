
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'Menus',
})
export default class MenusVue extends Vue {
  //public drawer :boolean = this.$store.state.menu.menuOption.drawer;;
  private responsive: boolean = false;

  //public mini: boolean = false;

  private get drawer(): boolean {
    return this.$store.state.menu.menuOption.drawer;
  }
  private set drawer(value: boolean) {
    this.$store.state.menu.menuOption.drawer = value;
  }
  private get menuActive(): number {
    return this.$store.state.menu.menuActive;
  }
  private set menuActive(value: number) {
    this.$store.state.menu.menuActive = value;
  }
  get Menus(): [object] {
    return this.$store.state.menu.menus;
  }
  get mini(): any {
    return this.$store.state.menu.mini;
  }
  set mini(value: any) {
    this.$store.state.menu.mini = value;
  }
  
  get userInfo(): any {
    return this.$store.getters.getUser;
  }

  show: boolean = true;

  created(): void {
    if(this.$route.query.mode == 'modal') {
      this.show = false;
    }
  }

  private mounted(): void {
    this.onResponsiveInverted();
    window.addEventListener('resize', this.onResponsiveInverted);
  }

  movePage(item: any): void {
    this.$router.push({ name: item.target }).catch(()=>{});
  }

  private onResponsiveInverted(): void {
    if (window.innerWidth < 1000) {
      this.responsive = true;
    } else {
      this.responsive = false;
    }
  }
  private moveHome(): void {
    this.$router.push({ name: 'homeList' }).catch(()=>{});
  }
}
