import axios, { AxiosError } from 'axios';
// import qs from 'qs'
import router from '@/router';

const API_URL = process.env.VUE_APP_API;
// axios.defaults.withCredentials = true

export default {
  async COUNT(id?, model='KM100'): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'Count',
          id,
          model
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async COUNTDETAIL(id?, model='KM100'): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'CountDetail',
          id,
          model
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async DETAIL(page, date?, filter?, model='KM100'): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'Detail',
          model,
          id: {
            생성일자: date,
            ...filter,
          },
          page
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const 단말Info = {
      data,
      errorMessage,
    };
    return 단말Info;
  },
  async SHOW_ALL(model='KM100'): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'ALL',
          model
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const departureInfo = {
      data,
      errorMessage,
    };
    return departureInfo;
  },
  async EDIT_MEMO(id:string, 비고:string|null): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/departure/edit",
        data: { id, 비고 },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.message === null ? '' : res.data.message;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const departureInfo = {
      data,
      errorMessage,
    };
    return departureInfo;
  },
  async FIND_AT_FILTER(page, date?, filter?, model='KM100'): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'Query',
          model,
          id: {
            생성일자: date,
            ...filter,
          },
          page
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const departureInfo = {
      data,
      errorMessage,
    };
    return departureInfo;
  },
  async FIND_AT_TIME(생성일자:string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'Detail',
          id: {
            생성일자
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const departureInfo = {
      data,
      errorMessage,
    };
    return departureInfo;
  },
  async FIND_AT_SERIAL(일련번호:string): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'get',
        url: API_URL + "v1/departure",
        params: {
          mode: 'Query',
          id: {
            일련번호
          }
        }
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        return {
          data: null,
          errorMessage: err.response?.data.message.errors
        }
      }
      return {
        data: null,
        errorMessage: "알수 없는 에러"
      }
    }
    console.log(res)
    const data = res.data.departureInfo === null ? '' : res.data.departureInfo;
    const errorMessage = res.data.errors === undefined ? '' : res.data.errors.message;
    const departureInfo = {
      data,
      errorMessage,
    };
    return departureInfo;
  },
  async REGISTER(
    제품명: string, 
    일련번호: Array<string>, 
    비고:string|null, 
    사용구분:string, 
    출고처_이름:string, 
    입고처_이름:string, 
    출고담당자_id:string,
    요청_id:string
  ): Promise<any> {
    let res
    try {
      res = await axios({
        method: 'post',
        url: API_URL + "v1/departure",
        data: { 제품명, 일련번호, 비고, 사용구분, 출고처_이름, 입고처_이름, 출고담당자_id, 요청_id },
      })
    }catch(err){
      if (err instanceof AxiosError) {
        if (err.response?.status === 401) {
          router.push({ name: 'Login' });
          return
        }
        console.log(err)
        return {
          code: err.response?.status,
          message: err.response?.data.message,
        }
      }
      return {
        code: 400,
        message: "알수 없는 에러"
      }
    }
    console.log(res)

    const departureInfo = {
      code: res.status,
      message: res.data.message,
    };
    return departureInfo;
  },
}  