
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';
import Common from '@/utils/common';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';

@Component({
  name: 'CorpOutput',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})
export default class CorpOutputVue extends Vue {
  get tab(): string {
    return this.$store.state.common.tab;
  }
  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  get ledgerResponse(): any {
    return this.$store.state.common.ledgerResponse;
  }
  get codeSelectList(): any {
    return this.$store.state.common.codeSelectList;
  }
  get fieldSearchResult(): any {
    return this.$store.state.common.fieldSearchResult;
  }
  get tableSearch(): any {
    return this.$store.state.common.tableSearch;
  }
  get routeList(): any {
    return this.$store.state.common.ledgerResponse.routeList;
  }

  async created(): Promise<void> {
    await this.getCorpList();
  }

  async getCorpList(): Promise<void> {
    await this.$store.dispatch(Constant.CORP_LIST, { tab: this.tab });
  }

  //페이징 동작
  async pageChanged(page: number): Promise<void> {
    this.$router.push({ name: 'ledgerList',  params: { tab: this.tab, page : String(page) } }).catch(()=>{});
    this.ledgerResponse.corpList.page = page;
    this.$store.dispatch(Constant.CORP_LIST, {} );
  }
  //아이템 등록 (모달)
  addItemModal(target: string, mode: string): void {
    this.$store.commit(Constant.OPEN_MODAL, { target, mode });
  }
  detailItemModal(item: any) {
    // this.$store.dispatch(Constant.CORP_DETAIL, { no, mode } );
    window.open("/#/modal/ledger/corpList?target="+Constant.CORP_DETAIL+"&mode=edit&no="+item.no, "PopupWin", "width=600,height=455")

  }
  async checkAuthUser (routerName:String): Promise<Boolean> {
    const token = localStorage.accessToken;
    let result = false;
    await this.$store.dispatch('CHECK_AUTH', { token, routerName } )
    if(this.userInfo.read_status == 1) {
      result = true
    } else { //0일때
      result = false
    }
    return result
  }
  async searchBranch(item: any) {
    const authPage = await this.checkAuthUser('branchs')
    if(authPage){
      if(0 < item.branch_count){
        //검색 조건을 먼저 초기화 해주고 corp_name,region1_code, region2_code를  넘겨준다
        for(const key in this.ledgerResponse.branchList.parameters){
          if(key === 'region1_code')
            this.ledgerResponse.branchList.parameters[key] = { //지역
              no: 0, 
              id: "ALL", 
              name: "전체"
            }
          else if(key === 'region2_code')
            this.ledgerResponse.branchList.parameters[key] = { //지역2
              no: 0, 
              id: "ALL", 
              name: "전체"
            }
          else this.ledgerResponse.branchList.parameters[key] = ''
        }
        this.ledgerResponse.branchList.parameters.corp_name = item.corp_name;
        this.ledgerResponse.branchList.parameters.corp_id = item.corp_id;
        this.ledgerResponse.branchList.parameters.corp_no = item.no;
        this.ledgerResponse.branchList.parameters.region1_code = item.region1_code;
        this.ledgerResponse.branchList.parameters.region2_code = item.region2_code;
        this.$store.state.common.tab = 'branchList';
        this.$router.push({ name: 'ledgerList',  params: { tab: 'branchList', page : String('1') } }).catch(()=>{});
        this.$store.commit(Constant.TAB_CHANGE, {index: 1});
      }

    }else{
      alert('해당페이지에 접근 권한이 없습니다.')
    }
  }
  async searchRoute(item: any) {
    if(0 < item.route_count){
      console.log(item.routes)
      const resultsList = item.routes;
      const totalCount = item.routes.length;
      const tab = 'corpList';
      await this.$store.commit('ROUTE_LIST_DETAIL', { resultsList, totalCount, tab } );
      this.routeList.externalRequest = true; 
      await localStorage.setItem('routeList', JSON.stringify(this.routeList));

      window.open("/#/modal/routeListModal?mode=detail", "PopupWin", "width=600,height=475")

      // this.routeList.routeListModalView = true; 
    }
  }
  async searchVehicle(item: any) {
    const authPage = await this.checkAuthUser('vehicles')
    if(authPage){
      console.log(item)
      if(0 < item.vehicle_count){
        //검색 조건을 먼저 초기화 해주고 corp_id를 넘겨준다
        this.ledgerResponse.vehicleList.parameters.branch_no = "";
        this.ledgerResponse.vehicleList.parameters.branch_name = "";
        this.ledgerResponse.vehicleList.parameters.oil_code = 0;
        this.ledgerResponse.vehicleList.parameters.vehicletype_code = 0;
        this.ledgerResponse.vehicleList.parameters.vehicle_id = "";
        this.ledgerResponse.vehicleList.parameters.year = "";
        this.ledgerResponse.vehicleList.parameters.brand_code = 0;
        this.ledgerResponse.vehicleList.parameters.vehicle_number = '';
        this.ledgerResponse.vehicleList.parameters.license_number = '';
        this.ledgerResponse.vehicleList.parameters.door_code = 0;
        this.ledgerResponse.vehicleList.parameters.route_code = '';
        this.ledgerResponse.vehicleList.parameters.model_code = 0;
        this.ledgerResponse.vehicleList.parameters.status_code = 0;
        this.ledgerResponse.vehicleList.parameters.corp_no = '';
        this.ledgerResponse.vehicleList.parameters.corp_id = "";
        this.ledgerResponse.vehicleList.parameters.use_status = null;
        this.ledgerResponse.vehicleList.parameters.corp_name = '';
        //
        this.ledgerResponse.vehicleList.parameters.corp_id = item.corp_id;
        this.ledgerResponse.vehicleList.parameters.corp_name = item.corp_name;
        //this.ledgerResponse.vehicleList.parameters.corp_no = Number(item.no);
        this.$store.state.common.tab = 'vehicleList';
        this.$router.push({ name: 'ledgerList',  params: { tab: 'vehicleList', page : String('1') } }).catch(()=>{});
        this.$store.commit(Constant.TAB_CHANGE, {index: 2});
      }
    }else{
      alert('해당페이지에 접근 권한이 없습니다.')
    }
  }
}
