import Common from './common';
import store from '@/store/index';
import router from '@/router/index';
import moment from 'moment';

export default {
  //긴 문자 ... 처리
  longText : (maxLength: any, target: any) => {
    return target.substr(0, maxLength) + '...';
  },

  //get 페이지 번호 파라미터 가져오기
  getParameter : (target: any) => {
    const fullUrl: any = window.location.href;
    const result: any = fullUrl.split(target + '=');
    return result[1];
  },
  
  //토큰 체크
  requireAuth : (from: any, to: any, next: any, routerName: any) => {
    if(sessionStorage.getItem("user") && sessionStorage.getItem("user") != "undefined") {
      //로그인이 되어있는 상태에서 필요한 로직 구현
      next()
    } else {
      router.push({
        name: 'Login'
      }).catch(error => {})
    }
  },

  //obj 초기화
  nullify : (obj: any): any => {
    let key;
    if (obj === '') { return obj; }
    for (key in obj) {
      if (typeof (obj[key]) !== 'object') {
        obj[key] = '';
      } else if (Array.isArray(obj[key])) {
        obj[key] = [];
      } else {
        Common.nullify(obj[key]);
      }
    }
    return obj;
  },

  //obj 데이터 초기화 new
  dataInit : (target: any): any => {
    const localKey: any = Object.keys(target);
    localKey.map((key: any, index: number) => {
      //console.log(typeof(target[key]))
      if (typeof(target[key]) === 'number') {
        target[localKey[index]] = 0;
      } else if (typeof(target[key]) === 'string') {
        target[localKey[index]] = '';
      } else if (typeof(target[key]) === 'object') {
        if (Array.isArray(target[key])) { //array
          target[key] = [];
        } else if (target[key] !== null) { //object
          Common.dataInit(target[key]);
        }
      } 
    });
    return target;
  },

  //validation 채크 
  validationCheck : async (content: any, validationCheckList: any): Promise<any> => {
    let res = true;
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < validationCheckList.length; i++) {
      if (validationCheckList[i].type === 'code') {
        if (content[validationCheckList[i].name] === '' || content[validationCheckList[i].name] === null || content[validationCheckList[i].name] === undefined || content[validationCheckList[i].name] === 0 ) {
          alert(validationCheckList[i].text + ' 코드는 필수 입력값 입니다.');
          res = false;
          break;
        } else {
          res = true;
        }
      } else if (validationCheckList[i].type === 'reg') {
        if (content[validationCheckList[i].name].registrationNumber === '' || content[validationCheckList[i].name].registrationNumber === null || content[validationCheckList[i].name].registrationNumber === undefined ) {
          alert(validationCheckList[i].text + ' 는 필수 입력값 입니다.');
          res = false;
          break;
        } else {
          res = true;
        }
      } else if (validationCheckList[i].type === 'normal') {
        if (content[validationCheckList[i].name] === '' || content[validationCheckList[i].name] === null || content[validationCheckList[i].name] === undefined ) {
          alert(validationCheckList[i].text + ' 는 필수 입력값 입니다.');
          res = false;
          break;
        } else {
          res = true;
        }
      } else if (validationCheckList[i].type === 'number') {
        if ( !( isFinite(content[validationCheckList[i].name]))  ) {
          alert(validationCheckList[i].text + ' 는 숫자만 입력 가능합니다.');
          res = false;
          break;
        } else if (content[validationCheckList[i].name] === '' || content[validationCheckList[i].name] === null || content[validationCheckList[i].name] === undefined ) {
          alert(validationCheckList[i].text + ' 는 필수 입력값 입니다.');
          res = false;
          break;
        } else {
          res = true;
        }
      }
    }
    return res;
  },

  //데이터 insert / update 사용시 데이터 공백 여부 확인하여 데이터 세팅  type: "int" | "str" , target
  rowDataSet : (type: string, target: any) => {
    if (type === 'str' && (target === null || target === '')) {
      return null;
    } else if (type === 'int' && (target === null || target === '')) {
      return null;
    } else if (type === 'int') {
      return target;
    } else if (type === 'str') {
      return '"' + target + '"';
    } else if(type === 'code' && (target === null || target === '' || target === undefined || target === 0)) {
      return null;
    } else if(type === 'code') {
      return target;
    } else if(type === 'date' && (target === null || target === '')) {
      return null;
    } else if(type === 'date') {
      return '"' + target + '"';
    } else if (type === 'bol') {
      return target ? 0 : 1;
    }
  },

  //null 확인
  checkNull: (target: any) => {
    if(target === null) {
      return null;
    } else {
      return target.no;
    }
  },

  //공백 "" -> null로 변환
  spaceFormat: (target: any) => {
    if(target === ""){
      return null;
    } else {
      return target;
    }
  },

  todayDate: (dest:number=0) => {
    let now = moment().subtract(dest, 'days');
    return now.format("YYYY-MM-DD");
  },
  todayMonth: (dest:number=0) => {
    let now = moment().subtract(dest, 'days');
    return now.format("YYYY-MM");
  },

  lastMonth: (month: number) => {
    var d = new Date();
    var monthOfYear = d.getMonth();
    d.setMonth(monthOfYear - month);

    var year = d.getFullYear();
    var mo = ("0"+(d.getMonth()+1)).slice(-2);
    var day = ("0"+d.getDate()).slice(-2);
    return year + '-' + mo + '-' + day;
  },

  codeNullCheck: (item: any) => {
    if(item == null) {
      return null;
    } else {
      return item.no;
    }
  },

  차량유형리스트: () => {
    return {
      '21': '법인',
      '22': '개인',
      '71': '법인(전기)',
      '72': '개인(전기)',
    }
  },

  사용구분리스트: () => {
    return [
      '개발', 
      '데모단말', 
      '설치', 
      '불량', 
      '회수', 
      '예비품', 
      '인증', 
      '테스트', 
      '생산'
    ]
  },

  모델명리스트: () => {
    return [
      "KM100",
      "KM200",
    ]
  },

  지역리스트: () => {
    return [
      "서울특별시",
      "부산광역시",
      "대구광역시",
      "인천광역시",
      "광주광역시",
      "대전광역시",
      "울산광역시",
      "세종특별자치시",
      "경기도 수원시",
      "경기도 성남시",
      "경기도 의정부시",
      "경기도 안양시",
      "경기도 부천시",
      "경기도 광명시",
      "경기도 평택시",
      "경기도 동두천시",
      "경기도 안산시",
      "경기도 고양시",
      "경기도 과천시",
      "경기도 구리시",
      "경기도 남양주시",
      "경기도 오산시",
      "경기도 시흥시",
      "경기도 군포시",
      "경기도 의왕시",
      "경기도 하남시",
      "경기도 용인시",
      "경기도 파주시",
      "경기도 이천시",
      "경기도 안성시",
      "경기도 김포시",
      "경기도 화성시",
      "경기도 광주시",
      "경기도 양주시",
      "경기도 포천시",
      "경기도 여주시",
      "경기도 연천군",
      "경기도 가평군",
      "경기도 양평군",
      "강원도 춘천시",
      "강원도 원주시",
      "강원도 강릉시",
      "강원도 동해시",
      "강원도 태백시",
      "강원도 속초시",
      "강원도 삼척시",
      "강원도 홍천군",
      "강원도 횡성군",
      "강원도 영월군",
      "강원도 평창군",
      "강원도 정선군",
      "강원도 철원군",
      "강원도 화처군",
      "강원도 양구군",
      "강원도 인제군",
      "강원도 고성군",
      "강원도 양양군",
      "충청북도 청주시",
      "충청북도 충주시",
      "충정북도 제천시",
      "충청북도 보은군",
      "충청북도 옥천군",
      "충청북도 영동군",
      "충청북도 증평군",
      "충청북도 진천군",
      "충청북도 괴산군",
      "충청북도 음성군",
      "충청북도 단양군",
      "충청남도 천안시",
      "충청남도 공주시",
      "충청남도 보령시",
      "충청남도 아산시",
      "충청남도 서산시",
      "충청남도 논산시",
      "충청남도 계룡시",
      "충청남도 당진시",
      "충청남도 금산군",
      "충청남도 부여군",
      "충청남도 서천군",
      "충청남도 청양군",
      "충청남도 홍성군",
      "충청남도 예산군",
      "충청남도 태안군",
      "전라북도 전주시",
      "전라북도 군산시",
      "전라북도 익산시",
      "전라북도 정읍시",
      "전라북도 남원시",
      "전라북도 김제시",
      "전라북도 완주군",
      "전라북도 진안군",
      "전라북도 무주군",
      "전라북도 장수군",
      "전라북도 임실군",
      "전라북도 순창군",
      "전라북도 고창군",
      "전라북도 부안군",
      "전라남도 목포시",
      "전라남도 여수시",
      "전라남도 순천시",
      "전라남도 나주시",
      "전라남도 광양시",
      "전라남도 담양군",
      "전라남도 곡성군",
      "전라남도 구례군",
      "전라남도 고흥군",
      "전라남도 보성군",
      "전라남도 화순군",
      "전라남도 장흥군",
      "전라남도 강진군",
      "전라남도 해남군",
      "전라남도 영암군",
      "전라남도 무안군",
      "전라남도 함평군",
      "전라남도 영광군",
      "전라남도 장성군",
      "전라남도 완도군",
      "전라남도 진도군",
      "전라남도 신안군",
      "경상북도 포항시",
      "경상북도 경주시",
      "경상북도 김천시",
      "경상북도 안동시",
      "경상북도 구미시",
      "경상북도 영주시",
      "경상북도 영천시",
      "경상북도 상주시",
      "경상북도 문경시",
      "경상북도 경산시",
      "경상북도 군위군",
      "경상북도 의성군",
      "경상북도 청송군",
      "경상북도 영양군",
      "경상북도 영덕군",
      "경상북도 청도군",
      "경상북도 고령군",
      "경상북도 성주군",
      "경상북도 칠곡군",
      "경상북도 예천군",
      "경상북도 봉화군",
      "경상북도 울진군",
      "경상북도 울릉군",
      "경상남도 창원시",
      "경상남도 진주시",
      "경상남도 통영시",
      "경상남도 사천시",
      "경상남도 김해시",
      "경상남도 밀양시",
      "경상남도 거제시",
      "경상남도 양산시",
      "경상남도 의령군",
      "경상남도 함안군",
      "경상남도 창녕군",
      "경상남도 고성군",
      "경상남도 남해군",
      "경상남도 하동군",
      "경상남도 산청군",
      "경상남도 함양군",
      "경상남도 거창군",
      "경상남도 합천군",
      "제주특별자치도",
    ]
  },
  datetime(datetime: string): string {
    return moment(datetime).format('YYYY-MM-DD HH:mm:ss');
  }
};