// import { mixin } from 'vue/types/umd';
// import * as type from './type';

export interface State {
  isLoading: boolean;
  modalLoading: boolean;
  myPage: boolean;
}

const state: State = {
  isLoading: false,
  modalLoading: false,
  myPage: false,
};
export default state;