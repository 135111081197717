
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {
  headers: object = [
    { text: 'ID', align: 'center', sortable: false, value: 'id', class: "white--text", },
    { text: '이름', align: 'center', sortable: false, value: '이름', class: "white--text", },
    { text: '전화번호', align: 'center', sortable: false, value: '전화번호', class: "white--text", }
  ]
  selectedRow: Array<Object | null> = []
  isLoading: boolean = true
  search: string = ''
  message: string = '안녕하세요.\n인터패스에서 안내 문자 보내드립니다.\n[메시지 내용]'
  
  parameters: Object | null = null;

  userList: any
  showP: boolean = false;

  IPP: number = 15
  page: number = 1
  pageLength: number = 0


  async pageChanged(page: number): Promise<void> {
    this.page = page;
  }

  async created(): Promise<void> {
    this.$store.commit('MENU_ACTIVE', { value: 11 });
    this.$store.commit('SET_TITLE', { title: '문자 전송', icon: 'sms' });
    this.userList = (await api.auth.USER_LIST()).data
    this.pageLength = Math.ceil(this.userList.length / this.IPP)

    console.log("user list:", this.userList)
    this.isLoading = false
  }
  async send() {
    this.isLoading = true
    try{
      const res = await api.SMS.MULTI(
        this.selectedRow.map((row: any) => row['전화번호']),
        this.message
      )
      console.log(res)
      alert('전송 완료')
    } catch (e) {
      console.error(e)
      alert('전송 실패')
    }
    this.isLoading = false
  }
}
