
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';

import api from '@/api'
import * as XLSX from 'xlsx/xlsx.mjs';
import { saveAs } from 'file-saver';
import moment from 'moment';

@Component({
  name: 'RequestDetailVue',
  components: {
    Loading,
  },
})
export default class RequestDetailVue extends Vue {
  history_headers: object = [
    {
      text: '상태',
      value: '상태',
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '일자', 
      value: '생성일자', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '담당자', 
      value: '유저.이름', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '특이사항', 
      value: '특이사항', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    }
  ]
  issue_header: object = [
    {
      text: '중분류',
      value: '중분류',
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },{ 
      text: '소분류', 
      value: '소분류', 
      align: 'center', 
      sortable: false, 
      class: 'font-weight-bold caption header-style white--text' 
    },
  ]
  APP_FILE = process.env.VUE_APP_FILE
  request: Object = {}
  출고일: String = ''
  history: Array<string | null> = []
  issue: Array<string | null> = []
  isLoading: boolean = true

  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  입출고일(생성일자) {
    
    return 생성일자 ? moment(생성일자).format('YYYY-MM-DD HH:mm:ss'): ''
  }
  compare( a, b ) {
  if ( a.중분류 < b.중분류 ){
    return -1;
  }
  if ( a.중분류 > b.중분류 ){
    return 1;
  }
  if ( a.소분류 < b.소분류 ){
    return -1;
  }
  if ( a.소분류 > b.소분류 ){
    return 1;
  }
  return 0;
  }

  async created(): Promise<void> {
    const res = await api.수리요청.FIND_AT_TIME(this.$route.query.id)
    console.log(res)
    this.request = res.message[0]
    this.history = this.request.수리요청기록
    this.issue = this.request.수리요청체크리스트.sort(this.compare);
    // this.출고일 = this.입출고일(this.request.입출고_기록?.생성일자)
    console.log(this.request)

    this.isLoading = false
  }

  async update(params: string): Promise<void> {
    this.isLoading = true
    var result = confirm(params + " 처리하시겠습니까?");
    if(result){
      const res = await api.수리요청.APPROVAL(this.$route.query.id, this.userInfo.id, params)
      
      alert(res.message)
      window.close()
    }else{
        
    }

    // const 요청정보 = await api.출고요청.FIND_AT_TIME(this.$route.query.id)
    // this.request = 요청정보.message[0]
    // this.history = this.request.출고요청기록
    // console.log(this.request)

    this.isLoading = false
  }
  async refresh(): Promise<void> {
    this.isLoading = true

    const 요청정보 = await api.출고요청.FIND_AT_TIME(this.$route.query.id)
    this.request = 요청정보.message[0]
    this.history = this.request.출고요청기록
    console.log(this.request)

    this.isLoading = false
  }

}
