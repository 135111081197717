import { RouteConfig } from 'vue-router';

import store from '@/store';
import Header from '@/components/common/Header.vue';
import Menus from '@/components/common/Menus.vue';

// import StorageRegister from '@/views/storage/popup/StorageRegister.vue';
import Dashboard from '@/views/dashboard/dashboard.vue';
import Summary from '@/views/dashboard/summary.vue';

import Common from '@/utils/common';

export const dashboardRoutes: RouteConfig[] = [
    {
        path: '/dashboard',
        name: 'dashboard',
        components: {
            header: Header,
            menu: Menus,
            content: Dashboard,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    {
        path: '/summary',
        name: 'summary',
        components: {
            header: Header,
            menu: Menus,
            content: Summary,
        },
        beforeEnter: (to, from, next) => Common.requireAuth(to, from, next, null),
    },
    // ...ledger,
    // ...doc,
];

