import {
  Menus,
  MenuOption,
} from './type';

export interface State {
  menus: any;
  menuActive: number;
  menuInfo: Menus;
  menuOption: MenuOption;
  mini: any;
  title: any;
  authList: any;
}

const state: State = {
  authList: null,
  title: {
    name: '',
    icon: '',
  },
  menuOption: {
    drawer: true,
  },
  menuActive: 0,
  menuInfo: {
    id: 0,
    icon: '',
    title: '',
    target: '',
    authority: false,
    childrens: [],
    level: 4,
  },
  menus: [
    // {
    //   id: 1,
    //   show: true,
    //   icon: 'dashboard',
    //   title: '대시보드',
    //   target: 'dashboard',
    //   authority: false,
    //   childrens: [],
    // },
    {
      id: 1,
      show: true,
      icon: 'tune',
      title: '관리자 기능',
      target: '',
      authority: false,
      level: 1,
      childrens: [
        {
          id: 11,
          show: true,
          icon: 'manage_accounts',
          title: '유저 관리',
          target: 'user',
          authority: false,
          childrens: [],
        },
        {
          id: 12,
          show: true,
          icon: 'corporate_fare',
          title: '업체 관리',
          target: 'storage',
          authority: false,
          childrens: [],
        },
      ],
    },
    {
      id: 2,
      show: false,
      icon: 'dashboard',
      title: '대시보드',
      target: 'dashboard',
      authority: false,
      childrens: [],
      level: 3,
    },
    {
      id: 4,
      show: true,
      icon: 'aod',
      title: '단말 관리',
      target: '',
      authority: false,    
      level: 3,
      childrens: [
        {
          id: 42,
          show: true,
          icon: 'vibration',
          title: '단말기 원장',
          target: 'device',
          authority: false,
          childrens: [],
        },
        {
          id: 43,
          show: true,
          icon: 'app_settings_alt',
          title: '단말기 수리',
          target: 'fix_request',
          authority: false,
          childrens: [],
        },
      ],
    },
    {
      id: 6,
      show: true,
      icon: 'receipt_long',
      title: '출고요청 관리',
      target: 'stock_request',
      authority: false,
      level: 4,
      childrens: [],
    },
    {
      id: 3,
      show: true,
      icon: 'inventory',
      title: '재고 관리',
      target: '',
      authority: false,      
      level: 3,
      childrens: [
        {
          id: 31,
          show: true,
          icon: 'summarize',
          title: '재고 현황',
          target: 'summary',
          authority: false,
          childrens: [],
        },
        {
          id: 32,
          show: true,
          icon: 'archive',
          title: '입·출고 관리',
          target: 'departure',
          authority: false,
          childrens: [],
        },
        {
          id: 33,
          show: true,
          icon: 'account_tree',
          title: '입·출고 상세',
          target: 'departureAll',
          authority: false,
          childrens: [],
        }
      ],
    },
    {
      id: 5,
      show: true,
      icon: 'local_taxi',
      title: '차량 관리',
      target: '',
      authority: false,    
      level: 3,
      childrens: [
        {
          id: 51,
          show: true,
          icon: 'manage_accounts',
          title: '등록 계정 관리',
          target: 'taxiUser',
          authority: false,
          childrens: [],
        },
        {
          id: 52,
          show: true,
          icon: 'dashboard',
          title: '차량 등록 현황',
          target: 'taxiSummary',
          authority: false,
          childrens: [],
        },
        {
          id: 53,
          show: true,
          icon: 'format_list_bulleted',
          title: '차량 등록 상세',
          target: 'taxi',
          authority: false,
          childrens: [],
        },
      ],
    },
  ],
  mini: true,
};

export default state;