
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Loading from '@/layout/Loading.vue';
import api from '@/api'
import common from '@/utils/common'
import * as XLSX from 'xlsx/xlsx.mjs';

@Component({
  name: 'DepatureOutVue',
  components: {
    Loading,
  },
})

export default class DepatureOutVue extends Vue {

  addressModal: boolean = false
  isLoading: boolean = true

  theme:Object = {
    bgColor: "#ECEFF1", //바탕 배경색
    searchBgColor: "#1197E1", //검색창 배경색
    //contentBgColor: "", //본문 배경색(검색결과,결과없음,첫화면,검색서제스트)
    pageBgColor: "#FFFFFF", //페이지 배경색
    //textColor: "", //기본 글자색
    queryTextColor: "#FFFFFF", //검색창 글자색
    postcodeTextColor: "#FF5252" //우편번호 글자색
    //emphTextColor: "", //강조 글자색
    //outlineColor: "", //테두리
  };

  get 지역리스트() {
    return common.지역리스트()
  }

  담당자: Array<string> = []

  주소: string = '';
  
  분류: string = "납품사";
  이름: string = "";
  지역: string = "";

  async created(): Promise<void> {
    const res = await api.auth.USER_LIST()
    this.담당자 = res.data.map(el => el.id)
    console.log(this.담당자)
    this.isLoading = false
  }
  onLoad() {
    console.log("load")
    this.주소 = ""
  }
  onComplete(result: VueDaumPostcodeCompleteResult) {
    this.주소 = result.address
    this.addressModal = false
    console.log(result)
  }
  async registration() {
    console.log("이름: ", this.이름)
    console.log("지역: ", this.지역)
    console.log("주소: ", this.주소)
    console.log("분류: ", this.분류)
    if((this.이름 == '' || this.이름 == null) ||
      (this.지역 == '' || this.지역 == null) ||
      (this.주소 == '' || this.주소 == null) ||
      (this.분류 == '' || this.분류 == null)){
      alert("입력되지 않은 값이 있습니다.")
      return
    }
    const res = await api.출고처.REGISTER(
      this.이름,
      this.지역,
      this.주소,
      this.분류,
    )
    console.log(res)
    if (res.code == 201){
      alert(res.message)
      window.close()
    } else {
      alert(res.message)
    }

    // if (this.parameters != null && this.devices.length != 0) {
    //   const res = await api.departure.REGISTER(
    //     this.품목명,
    //     this.devices,
    //     this.비고,
    //     this.사용구분,
    //     this.parameters.이름,
    //     '인터패스',
    //     this.$route.query.id,
    //   )
    //   console.log(res)
    //   if (res.code == 201){
    //     alert(res.message)
    //     window.close()
    //   } else {
    //     alert(res.message)
    //   }
    // } else {
    //   alert("입력되지 않은 값이 있습니다.")
    // }
  }

}
