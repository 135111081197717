
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import Constant from '@/utils/constant';

import Common from '@/utils/common';
import PWChangeModal from './PWChangeModal.vue';

@Component({
  name: 'MyPage',
  components: {
    PWChangeModal
  },
})

export default class MyPageVue extends Vue {

  get userInfo(): any {
    return this.$store.getters.getUser;
  }
  
  
  pwModalView: boolean = false;

  created(): void {
    console.log(this.userInfo)
  }

  pwUpdate(): void {
    this.pwModalView = true;
  }
}
