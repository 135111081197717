
  import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
  import DepartureList from './DepartureList.vue';
  
  @Component({
    name: 'DepartureAllIndex',
    components: {
      DepartureList,
    },
  })
  export default class  DepartureAllIndex extends Vue {
    tab = null
    items = [
      'KM100', 'KM200'
    ]
  
  }
  