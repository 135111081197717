
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

import TableFiledCeckBox from '@/components/common/TableFiledCeckBox.vue';
import TableRowSelect from '@/components/common/TableRowSelect.vue';
import api from '@/api'
import common from '@/utils/common';

@Component({
  name: 'DepartureTable',
  components: {
    TableFiledCeckBox,
    TableRowSelect,
  },
})

export default class DepartureTableVue extends Vue {
  @Prop() 품목명!: string;

  itemRefs = [];
  first = true;

  headers = [
    { text: '지역', value: '출고처.지역', align: 'center', width: '10%', sortable: false, groupable: false,},
    { text: '지역', value: '', align: 'center', width: '10%', sortable: false, groupable: false,},
    { text: '운수사', value: '출고처.이름', align: 'center', width: '10%', sortable: false, groupable: false, },
    { text: '수량', value: 'count', align: 'center', width: '10%', sortable: false, groupable: false, },
  ];
  data = [];
  IPP = 10;

  setItemRef(el) {
    this.itemRefs.push(el)
  }

  numbering(number): string {
    if (number == 0) {
      return "-"
    } else {
      return number.toString()
        .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
  }
  async updated(): Promise<void> {
    if (this.first == true) {
      Object.keys(this.itemRefs).forEach(k => {
        this.itemRefs[k].$el.click();
      })
      this.first = false
    }
  }

  async created(): Promise<void> {
    const data = await api.단말.COUNT({ 제품명: this.품목명 })
    console.log(data)
    this.data = data.data
    this.IPP = this.data.length;
    // let depBody: Array<{
    //   지역: string,
    //   수량: number,
    // }> = []

    // data.data.forEach(element => {
    //   const index = depBody.findIndex(e => e.지역 === element.출고처.지역)
    //   if (index != -1) {
    //     depBody[index].수량 += element.count
    //   } else {
    //     depBody.push({
    //       지역: element.출고처.지역,
    //       수량: element.count,
    //     })
    //   }
    // })

    // this.data = depBody as any;
    // this.IPP = this.data.length;
  }

}
