import { MutationTree } from 'vuex';
import { State } from './state';
//import axios from "axios";

const mutation: MutationTree<State> = {
  //로딩바
  CHANGE_ISLOADING(state: State, payload: any): void {
    state.isLoading = payload.loadingStatus;
  },

  //모달창 로딩바
  MODAL_ISLOADING(state: State, payload: any): void {
    state.modalLoading = payload.loadingStatus;
  },
};

export default mutation;